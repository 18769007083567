import React from 'react';

const TermsConditions = props => {
  return (
    <div className="container" style={{ maxWidth: '600px' }}>
      <div className="row justify-content-center">
        <div className="col-12 content-left">
          <div className="d-md-block text-left heading_box top_0">
            <div className="mt-0">
              <div className="w_40" onClick={props.onClickBack}>
                <img src="/img/back.svg" alt="" />
              </div>
              <h5 className="heading1 text-left mt-4">Terms & Conditions</h5>
            </div>
            <p className="sub_heading text-left">
              It is mutually agreed between FilmFolk and the person making the
              booking that the following terms and conditions form an integral
              part of this contract and that no variation or modification shall
              be effective, unless accepted by FilmFolk in writing.
            </p>
          </div>

          <div className="overflow_bg pt-0">
            <div className="row justify-content-center">
              <div className="col-md-12 col-12">
                <div className="mt-2">
                  <h6 className="text-theme">Payments</h6>
                  <p className="m-0">
                    A 50% deposit is required to secure a booking with the
                    balance payable before the project date. Any upgrades after
                    booking will be subject to current availability and pricing.
                    Downgrades are not permitted except under the cancellation
                    terms below. Final delivery cannot be made if there are any
                    outstanding payments due.
                  </p>
                </div>

                <div className="mt-2">
                  <h6 className="text-theme">Amendments</h6>
                  <p className="m-0">
                    If you wish to amend your project date or hours booked, you
                    may do without penalty up to 7 days before the first
                    contracted date, subject to our availability. All monies
                    paid will be applied to the booking dates and hours and
                    dates, however no refunds on monies already paid will be
                    permitted.
                  </p>
                </div>

                <div className="mt-2">
                  <h6 className="text-theme">Cancellation</h6>
                  <p className="m-0">
                    In the event of you wishing to cancel your booking, FilmFolk
                    will charge a cancellation fee in accordance with the
                    following scale: 50% of the project fee if cancelled more
                    than 2 days prior to the date of the project; 100% of the
                    project fee if cancelled less than 2 days of the project.
                  </p>
                </div>

                <div className="mt-2">
                  <h6 className="text-theme">Attendance</h6>
                  <p className="m-0">
                    The crew will attend for the number of hours stated on the
                    contract. Attendance includes time for capturing creative
                    shots and transferring between shooting locations (where
                    necessary). Any additional attendance requested by you (e.g.
                    your schedule runs over), will be billed after the event at
                    the prevailing rate specified.
                  </p>
                </div>

                <div className="mt-2">
                  <h6 className="text-theme">Travel</h6>
                  <p className="m-0">
                    Within England & Wales travel will be charged at 50p/mile
                    from our Central London studio (WC1H). A minimum charge of
                    £50 applies. Travel is charged separately for each crew
                    member. If travel exceeds 100 miles on a standard 8-hour day
                    (or filming starts before 8am and/or finishes after
                    midnight), overnight accommodation will be charged at £75
                    per crew member per night. For international travel we
                    charge £250 per crew member to cover travel time. All
                    flights, baggage, accommodation and local expenses are
                    passed on at cost plus VAT. An invoice for travel expenses
                    will be sent after the event and must be paid before final
                    delivery.
                  </p>
                </div>

                <div className="mt-2">
                  <h6 className="text-theme">Production Planning</h6>
                  <p className="m-0">
                    Clients are required to provide a detailed information about
                    their project requirements in advance through our Project
                    Planning Interface. Information includes a detailed schedule
                    of the day's events, key participants and venue information.
                    This information should be supplied at least 1 week before
                    the project date. Failure to do so may compromise the
                    quality and coverage of the finished work.
                  </p>
                </div>

                <div className="mt-2">
                  <h6 className="text-theme">Liability & Force Majeure</h6>
                  <p className="m-0">
                    The company's total liability shall in all circumstances be
                    limited to the net contract value. In all cases the company
                    will not be liable for consequences owing to circumstances
                    beyond its or its employees' control. In the event of
                    inclement weather on the day, the crew, in co-operation with
                    the client will make necessary changes to the schedule and
                    will do their best to produce coverage for the project
                    within the time allocated. Should delays occur due to
                    circumstances outside their control, the crew will stay on
                    beyond the agreed time but will charge an additional fee
                    (see above).
                  </p>
                </div>

                <div className="mt-2">
                  <h6 className="text-theme">Meals & Breaks</h6>
                  <p className="m-0">
                    For bookings with over 6 hours attendance the crew will be
                    entitled to a 30-minute break during the day (taken at a
                    mutually convenient time, usually during a formal dinner).
                    The client is required to provide a hot meal and
                    refreshments at this time. This should be served in a prompt
                    manner to ensure crew are ready to resume coverage after the
                    meal. In the event of meals and refreshments not being
                    provided (or within a practical timeframe), a charge of
                    £40.00 per crew member will be made.
                  </p>
                </div>

                <div className="mt-2">
                  <h6 className="text-theme">Coverage</h6>
                  <p className="m-0">
                    FilmFolk will not be held responsible for any restrictions
                    placed by officials, venue staff and/or disruptions caused
                    by guests during video recording. Whilst every effort is
                    made to provide comprehensive coverage of events, FilmFolk
                    does not guarantee to capture any specific item and the
                    crew's judgment regarding filming conditions shall be deemed
                    correct. Any client booking a single camera package
                    acknowledges this may result in restricted material during
                    ceremonies, speeches and live performances. The client also
                    agrees it is their responsibility to obtain permission for
                    filming from all parties involved.
                  </p>
                </div>

                <div className="mt-2">
                  <h6 className="text-theme">Video Editing</h6>
                  <p className="m-0">
                    Post-production on video projects usually takes 1-3 months.
                    Work cannot commence until all outstanding payments and
                    information requests have been settled. If this is not
                    settled within one year of the event, the project will be
                    archived and deemed complete. The crew member is responsible
                    for overseeing the editing stage and their creative and
                    technical judgment regarding the work is final. Any requests
                    for amendments after delivery must be made in writing within
                    2 weeks. No charge will be made for technical errors that
                    are within our control, however any creative or editorial
                    changes are subject to feasibility and charged at our
                    prevailing studio rate. The project will be archived two
                    months after delivery after which no further changes will be
                    possible.
                  </p>
                </div>

                <div className="mt-2">
                  <h6 className="text-theme">Photo Editing</h6>
                  <p className="m-0">
                    Post-production on photography projects usually takes 1-4
                    weeks. Work cannot commence until all outstanding payments
                    and information requests have been settled. If this is not
                    settled within one year of the event, the project will be
                    archived and deemed complete. For album orders the client
                    may select the recommended number of images for the design.
                    An electronic proof copy of the album will be supplied for
                    approval and clients will have 2 weeks in which to request
                    one set of design changes. These changes will be subject to
                    feasibility, but will not be charged for. Any subsequent
                    changes will be charged at £150 per set. If for any reason
                    the client does not approve their album design within two
                    months, the project will be archived and deemed complete.
                    Once the client has approved their album design it will be
                    manufactured and dispatched within 1 month.
                  </p>
                </div>

                <div className="mt-2">
                  <h6 className="text-theme">Usage & Copyright</h6>
                  <p className="m-0">
                    The copyrights in all work shall remain the property of
                    FilmFolk in accordance with the Copyright, Design and
                    Patents Act 1998. Clients are entitled to full private usage
                    rights, but may not allow material to be transmitted on TV
                    or published in magazines without the producer's consent.
                    The client hereby acknowledges that infringement of
                    FilmFolk's copyright is unlawful and may be a criminal
                    offence. Footage and stills may be used by FilmFolk on its
                    own website and social networks and consent for this is
                    hereby given by the client.
                  </p>
                </div>

                <div className="mt-2">
                  <h6 className="text-theme">
                    Please take special note of the following conditions in our
                    Filming Contract
                  </h6>
                  <p className="">
                    <img className="mr-2" src="/img/y_dot.svg" alt="" />
                    Detailed instructions for your project must be provided no
                    less than 1 week prior to the booking date.
                  </p>
                  <p className="">
                    <img className="mr-2" src="/img/y_dot.svg" alt="" />
                    Any extra attendance or travel required on the project will
                    billed after the event and must be settled before receiving
                    delivery.
                  </p>
                  <p className="">
                    <img className="mr-2" src="/img/y_dot.svg" alt="" />
                    For bookings over 6 hours, our crew require a 30-minute
                    break as part of the project hours (taken at mutually
                    convenient time) during which you will provide a hot meal.
                  </p>
                  <p className="">
                    <img className="mr-2" src="/img/y_dot.svg" alt="" />
                    Video editing normally takes 1-3 months, photo editing 1-4
                    weeks. An express edit service is available subject to costs
                    and availability.
                  </p>
                  <p className="">
                    <img className="mr-2" src="/img/y_dot.svg" alt="" />
                    If you would like to make any changes to edited projects
                    after receiving your deliverables, you may do so within the
                    terms stated.
                  </p>
                  <p className="">
                    <img className="mr-2" src="/img/y_dot.svg" alt="" />
                    We retain the copyright to all productions and may use clips
                    or stills to show prospective clients.
                  </p>
                </div>
              </div>
            </div>

            <div className="text-center my-5 main_btn left_50">
              <button
                type="button"
                className="btn btn-warning rounded-pill text-uppercase text-center my-5"
                onClick={props.onConfirm}
              >
                Confirm contract
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsConditions;
