import React from 'react';
import { connect } from 'react-redux';

import { loading } from '../redux/actions';
import { getPackages } from '../redux/actions';
import * as Constant from '../constants';
import Api from '../utilities/api';
import * as Func from '../utilities/func';
import Card from './common/Card';

class Package extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: props.packages.length === 1 ? props.packages[0].id : null,
      selected2: props.packages.length === 1 ? true : null,
      packages: props.packages,
    };
  }

  componentDidMount() {
    // if (!this.props.project_packages) {
    this.props.getPackages(
      this.props.package_type?.id,
      this.props.project_type?.id
    );
    // }
  }

  onClickAdd = async selected => {
    if (this.state.packages.find(c => c.id === selected)) {
      this.setState({ selected });
    } else {
      const { project_packages, package_type, project_type } = this.props;
      const p = project_packages[package_type.id].find(c => c.id === selected);

      const rp = this.state.packages.find(
        c => c.service_type === p.service_type
      );
      if (rp) {
        // remove existing package of same type
        this.setState(prevState => ({
          selected: null,
          packages: prevState.packages.filter(c => c.id !== rp.id),
        }));
      }

      this.props.loading(true);
      const response = await Api.get(
        `pricings?package_id=${p.id}&project_type_id=${project_type.id}`
      );
      this.props.loading(false);

      this.setState(prevState => ({
        selected,
        packages: [
          ...prevState.packages,
          {
            id: p.id,
            quantity: 1,
            name: p.name,
            service_id: p.service_id,
            service_type: p.service_type,
            currency_price: p.currency_price,
            pricings: response.data.data,
          },
        ],
      }));
      window.scrollTo(0, 0);
    }
  };

  onClickRemove = selected => {
    if (this.state.selected2) {
      this.setState({ selected2: false });
    } else {
      this.setState(prevState => ({
        selected: null,
        packages: prevState.packages.filter(c => c.id !== selected),
      }));
    }
    window.scrollTo(0, 0);
  };

  onClickConfirm = () => {
    if (this.state.selected2) {
      this.props.onConfirm(this.state.packages);
    } else {
      this.setState({ selected2: true });
      window.scrollTo(0, 0);
    }
  };

  onClickMinus = () => {
    const { selected } = this.state;
    const index = this.state.packages.findIndex(c => c.id === selected);
    this.setState(prevState => {
      const packages = [...prevState.packages];
      if (packages[index].quantity > 1) {
        packages[index] = {
          ...packages[index],
          quantity: packages[index].quantity - 1,
        };
      }
      return { packages };
    });
  };

  onClickPlus = () => {
    const { selected, packages } = this.state;
    const index = this.state.packages.findIndex(c => c.id === selected);
    if (packages[index].quantity < 3) {
      this.setState(prevState => {
        const packages = [...prevState.packages];
        packages[index] = {
          ...packages[index],
          quantity: packages[index].quantity + 1,
        };
        return { packages };
      });
    }
  };

  getCrewString = () => {
    const { package_type } = this.props;
    if (package_type.id === Constant.PHOTOGRAPHY_PACKAGE) {
      return 'Photographers';
    }
    if (package_type.id === Constant.VIDEOGRAPHY_PACKAGE) {
      return 'Videographers';
    }
    if (package_type.id === Constant.COMBINED_PACKAGE) {
      return 'Videographers';
    }
  };

  renderQuantitySelector = (p, selectedPackage) => {
    return (
      <>
        <div className="text-center mt-4">
          <p>Add Extra {this.getCrewString()}</p>
        </div>

        <div className="d-flex justify-content-center quantity_box mt-4">
          <span className="bg-transparent" onClick={this.onClickMinus}>
            <img src="/img/minus.svg" alt="" />
          </span>
          <span className="mx-3">{selectedPackage.quantity}</span>
          <span className="bg-transparent" onClick={this.onClickPlus}>
            <img src="/img/plus.svg" alt="" />
          </span>
        </div>
      </>
    );
  };

  renderSelected2 = () => {
    const { selected } = this.state;
    const { project_packages, package_type, datetimes } = this.props;
    const p = project_packages[package_type.id]?.find(c => c.id === selected);
    const selectedPackage = this.state.packages.find(c => c.id === selected);
    if (p) {
      return (
        <div className="">
          <p className="text-center">
          We recommend extra crew for projects where more varied material is required or simultaneous coverage of different elements. The price indicated below is based on the number of crew and duration of attendance.
          </p>
          <div className="d-flex justify-content-center">
            <Card
              selected
              icon={p.icon_url}
              text={p.name}
              badge={'£' + Func.getPackagePrice(datetimes, selectedPackage)}
              quantity={selectedPackage.quantity}
              close
              onClose={() => this.onClickRemove(selected)}
            />
          </div>
          {this.renderQuantitySelector(p, selectedPackage)}
        </div>
      );
    }
    return null;
  };

  renderSelected = () => {
    const { selected } = this.state;
    const { project_packages, package_type, datetimes } = this.props;
    const p = project_packages[package_type.id].find(c => c.id === selected);
    const selectedPackage = this.state.packages.find(c => c.id === selected);
    if (p) {
      return (
        <div className="row justify-content-center">
          <Card
            selected
            icon={p.icon_url}
            text={p.name}
            badge={'£' + Func.getPackagePrice(datetimes, selectedPackage)}
            quantity={selectedPackage.quantity}
            close
            onClose={() => this.onClickRemove(selected)}
          />
          <ul className="list-group mt-4">
            {p.features.map(f => (
              <li
                key={f.id}
                className="list-group-item d-flex justify-content-start align-items-center bg-transparent border-0 my-2"
              >
                <span>
                  <img className="mr-2" src={f.icon_url} alt={f.icon_url} />
                </span>
                {f.text}
              </li>
            ))}
          </ul>
        </div>
      );
    }
    return null;
  };

  renderButton = () => {
    const { packages } = this.state;

    if (packages.length > 0) {
      return (
        <button
          type="button"
          className="btn btn-warning rounded-pill text-uppercase"
          onClick={this.onClickConfirm}
        >
          Confirm
        </button>
      );
    }

    return (
      <button
        type="button"
        className="btn btn-grey rounded-pill text-uppercase"
      >
        Select an option
      </button>
    );
  };

  render() {
    if (!this.props.project_packages) {
      return null;
    }
    const { selected, selected2, packages } = this.state;
    const { project_packages, package_type } = this.props;
    return (
      <>
        <div className="d-md-block text-center heading_box">
          {!(selected && selected2) && (
            <>
              <h3 className="heading1">Choose your package</h3>
              <p className="sub_heading">
                Choose the type of package that best meets your needs.
              </p>
            </>
          )}
          {selected && selected2 && (
            <>
              <h3 className="heading1">Crew Details</h3>
              <p className="sub_heading">
                Choose the number of {this.getCrewString()} you want in your
                package.
              </p>
            </>
          )}
        </div>

        <div className="overflow_bg">
          <div className="row justify-content-center">
            <div className="col-12">
              {!selected && (
                <div className="row justify-content-center">
                  {project_packages[package_type.id]?.map(p => {
                    const selectedPackage = packages.find(c => c.id === p.id);
                    return (
                      <Card
                        key={p.id}
                        selected={!!selectedPackage}
                        icon={p.icon_url}
                        text={p.name}
                        quantity={packages.find(c => c.id === p.id)?.quantity}
                        close={!!selectedPackage}
                        onClose={() => this.onClickRemove(p.id)}
                        onClick={() => this.onClickAdd(p.id)}
                        badge="2.5 mins"
                      />
                    );
                  })}
                </div>
              )}
              {selected && !selected2 && this.renderSelected()}
              {selected && selected2 && this.renderSelected2()}
            </div>
          </div>

          <div className="text-center my-5 main_btn">{this.renderButton()}</div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ packages }, props) => ({
  project_packages: packages[props.project_type?.id],
});
export default connect(mapStateToProps, { loading, getPackages })(Package);
