const calculatePrice = (pricings, duration, quantity) => {
  let price = 0;
  let pricing = pricings.find(
    p => p.no_of_staff === quantity && p.no_of_hours === duration
  );

  if (pricing) {
    return pricing.price * 1;
  } else {
    let t_pricings = pricings.filter(p => p.no_of_staff === quantity);
    t_pricings.sort((a, b) => a.no_of_hours - b.no_of_hours);
    price += t_pricings[t_pricings.length - 1].price * 1;
    price +=
      t_pricings[0].price *
      (duration - t_pricings[t_pricings.length - 1].no_of_hours);
  }
  return price;
};

const getPackagePrice = (datetimes, p) => {
  let price = 0;
  if (datetimes.length > 0) {
    const quantity = p.quantity ? p.quantity : 1;
    datetimes.map((dt, dti) => {
      let pricings = dti > 0 ? p.pricings.additional : p.pricings.initial;
      price += calculatePrice(pricings, dt.duration, quantity);
      return null;
    });
  }
  return price;
};

export { getPackagePrice, calculatePrice };
