import React from 'react';
const Card = props => {
  const {
    selected,
    icon,
    text,
    badge,
    quantity,
    close,
    onClose,
    onClick,
  } = props;
  const icons = icon && typeof icon === 'object' ? icon : [icon];

  return (
    <div className="">
      <div
        className={`box${selected ? ' border btn-outline-warning ' : ''}`}
        onClick={onClick}
      >
        {close && (
          <div
            className="box-close"
            onClick={e => {
              e.stopPropagation();
              onClose();
            }}
          >
            <p className="m-0">
              <i className="fas fa-times"></i>
            </p>
          </div>
        )}
        <div>
          {icons.map(icon => (
            <img src={icon} alt={icon} key={icon} />
          ))}
          <p className={`${selected ? ' text-warning' : ''}`}>{text}</p>
          {quantity && (
            <div className="box-count">
              <p className="m-0 text-dark">{quantity}</p>
            </div>
          )}
          {badge && (
            <span className="badge badge-warning ml-1 px-2 py-1 ">{badge}</span>
          )}
        </div>
      </div>
    </div>
  );
};

Card.defaultProps = {
  selected: false,
  badge: null,
  quantity: null,
  close: false,
  onClose: () => {},
  onClick: () => {},
};

export default Card;
