import React from 'react';
import { connect } from 'react-redux';
import Api from '../utilities/api';
import { loading } from '../redux/actions';

class EditingBrief extends React.Component {
  constructor(props) {
    super(props);

    const editing_brief = props.editing_brief
      ? props.editing_brief
      : {
          brief: '',
          upload_file: false,
          brief_file: null,
          provide_later: false,
        };

    this.state = {
      ...editing_brief,
      error: false,
    };
  }

  onClickConfirm = () => {
    const { brief, upload_file, brief_file, provide_later } = this.state;

    this.props.onConfirm({
      brief,
      upload_file,
      brief_file,
      provide_later,
    });
  };

  uploadFile = async (name, file) => {
    // Create an object of formData
    const formData = new FormData();

    const re = /(?:\.([^.]+))?$/;
    const ext = re.exec(file.name)[1];
    if (!['doc', 'docx', 'rtf', 'pdf'].includes(ext)) {
      this.setState({ error: 'This File Type is Not Supported' });
      return;
    }

    this.props.loading(true);

    // Update the formData object
    formData.append('file', file, file.name);

    // Request made to the backend api
    // Send formData object
    try {
      const response = await Api.post('bookings/media', formData);
      const errors = { ...this.state.errors };
      this.setState({
        [name]: response.data,
        errors: { ...errors },
        error: false,
      });
      this.props.loading(false);
    } catch (err) {
      const data = err.response ? err.response.data : {};
      console.log(data.errors);
      this.setState({ error: data.message });
      this.props.loading(false);
    }
  };

  onChangeInput = ({ target }) => {
    const { name } = target;
    const errors = { ...this.state.errors };
    const value = target.type === 'checkbox' ? target.checked : target.value;

    if (target.type === 'file') {
      this.setState({ error: false });
      if (target.files[0]) {
        this.uploadFile(name, target.files[0]);
      }
      return;
    }

    this.setState({ [name]: value, errors: { ...errors }, error: false });
  };

  renderButton = () => {
    const { brief, upload_file, brief_file, provide_later, error } = this.state;

    if (error) {
      return (
        <button
          type="button"
          className="btn btn-grey rounded-pill text-uppercase"
        >
          Fix issues
        </button>
      );
    }

    if (brief || (upload_file && brief_file) || provide_later) {
      return (
        <button
          type="button"
          className="btn btn-warning rounded-pill text-uppercase"
          onClick={this.onClickConfirm}
        >
          Confirm
        </button>
      );
    }

    if (upload_file && !brief_file) {
      return (
        <button
          type="button"
          className="btn btn-grey  rounded-pill text-uppercase"
          onClick={() =>
            this.setState({ error: 'Please select a file or go back' })
          }
        >
          Upload your brief
        </button>
      );
    }

    return (
      <button
        type="button"
        className="btn btn-grey  rounded-pill text-uppercase"
      >
        Provide Instructions
      </button>
    );
  };

  renderForm = () => {
    const { error, brief, upload_file, brief_file, provide_later } = this.state;

    return (
      <form className="custom-form">
        {!upload_file && !provide_later && (
          <div className="form-group">
            <label className="textarea_label" htmlFor="brief">
              EDITING BRIEF
            </label>
            <textarea
              className="form-control pt-4 textarea_c h_200"
              rows="5"
              name="brief"
              id="brief"
              placeholder="Type here..."
              value={brief}
              onChange={this.onChangeInput}
            ></textarea>
          </div>
        )}

        {!brief && !provide_later && (
          <div className="form-group">
            <div className={`b_r_15`}>
              <div
                className={`my_checked_bg ${upload_file ? 'checked' : ''}`}
                onClick={() =>
                  this.onChangeInput({
                    target: {
                      type: 'checkbox',
                      name: 'upload_file',
                      checked: !upload_file,
                    },
                  })
                }
              >
                <div className="my_check">
                  <img
                    className="my_check_img"
                    src={upload_file ? '/img/tick_y.svg' : '/img/tick_b.svg'}
                    alt=""
                  />
                </div>
                <h6 className={`m-0 ${upload_file ? 'text-dark' : ''}`}>
                  Upload a file
                </h6>
              </div>
            </div>
          </div>
        )}

        {upload_file && (
          <div
            className={`my-4 d-flex align-items-center justify-content-center upload_box ${
              error ? 'file-error' : brief_file ? 'bg-warning' : ''
            }`}
          >
            {error && (
              <div className="text-center text-dark">
                <label htmlFor="brief_file">
                  <img src="/img/file_red.svg" alt="" />
                  <h6 className="mt-3 text-danger">{error}</h6>
                </label>
              </div>
            )}

            {!error && brief_file && (
              <div className="text-center text-dark">
                <label htmlFor="brief_file">
                  <img src="/img/file_black.svg" alt="" />
                  <h6 className="mt-3">{brief_file.original_name}</h6>
                </label>
              </div>
            )}

            {!error && !brief_file && (
              <div className="text-center">
                <label className="position-static" htmlFor="brief_file">
                  <img src="/img/upload.svg" alt="" />
                  <h6 className="mt-4 mb-3 font-weight-light">
                    Tap to choose file...
                  </h6>
                  <h6 className="mt-2 font-weight-light">
                    DOCX / RTF / PDF / Max 5MB
                  </h6>
                </label>
              </div>
            )}

            <input
              style={{ display: 'none' }}
              type="file"
              name="brief_file"
              id="brief_file"
              accept=".doc,.docx,.rtf,.pdf"
              onChange={this.onChangeInput}
            />
          </div>
        )}

        {!brief && !upload_file && (
          <div className="form-group">
            <div className={`b_r_15`}>
              <div
                className={`my_checked_bg ${provide_later ? 'checked' : ''}`}
                onClick={() =>
                  this.onChangeInput({
                    target: {
                      type: 'checkbox',
                      name: 'provide_later',
                      checked: !provide_later,
                    },
                  })
                }
              >
                <div className="my_check">
                  <img
                    className="my_check_img"
                    src={provide_later ? '/img/tick_y.svg' : '/img/tick_b.svg'}
                    alt=""
                  />
                </div>
                <h6 className={`m-0`}>I'll complete this later</h6>
              </div>
            </div>
          </div>
        )}
      </form>
    );
  };

  renderInfo = () => {
    const { brief, upload_file, brief_file, provide_later } = this.state;

    if (brief || (upload_file && brief_file) || provide_later) {
      return (
        <p className="col-12 mt-4 text-center w-75">
           If after reviewing your editing instructions the cost is likely to be more than the estimate, we will notify you before starting and you can cancel without penalty
        </p>
      );
    }
  };

  render() {
    return (
      <>
        <div className="d-md-block text-center heading_box">
          <h3 className="heading1">EDITING BRIEF</h3>
          <p className="sub_heading">
            Please provide any special instructions on your editing project.
            More information can be supplied later.
          </p>
        </div>

        <div className="overflow_bg pt-0">
          <div className="row justify-content-center">
            <div className="col-12">{this.renderForm()}</div>
            {this.renderInfo()}
          </div>

          <div className="text-center my-5 main_btn">{this.renderButton()}</div>
        </div>
      </>
    );
  }
}

export default connect(() => ({}), { loading })(EditingBrief);
