import React from 'react';

const ProgressBar = props => {
  const { step, total } = props;
  return (
    <div
      className={`progress-bar ${step >= total ? 'bg-success' : ''}`}
      style={{ width: `${Math.min(step / total, 1) * 100}%` }}
    ></div>
  );
};

export default ProgressBar;
