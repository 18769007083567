import React from 'react';
import { connect } from 'react-redux';

import { getProjectTypes, getProjects } from '../redux/actions';
import Card from './common/Card';

class ProjectType extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: props.project_type,
      project: props.project,
    };
  }

  componentDidMount() {
    if (!this.props.projectTypes) {
      this.props.getProjectTypes();
    }
  }

  onClickConfirm = () => {
    this.props.onConfirm(this.state.selected, this.state.project);
  };

  renderOptions = type => {
    const { project } = this.state;
    if (!this.props.projects[type]) {
      this.props.getProjects(type);
      return null;
    }

    const projects = this.props.projects[type];
    return (
      <div className="position-relative custom-select-arrow">
      <select
        className="custom-select mt-5"
        value={project ? project.id : ''}
        onChange={e =>
          this.setState({
            project: {
              id: parseInt(e.target.value),
              name: projects.find(p => p.id === parseInt(e.target.value)).name,
            },
          })
        }
      >
        <option value="">Make a selection...</option>
        {projects.map(p => (
          <option key={p.id} value={p.id}>
            {p.name}
          </option>
        ))}
      </select>
      </div>
    );
  };

  renderSelected = () => {
    const { selected } = this.state;
    const type = this.props.projectTypes.find(t => t.id === selected.id);
    if (type) {
      return (
        <div>
          <div className="row justify-content-center">
            <Card
              selected
              icon={type.icon_url}
              text={type.name}
              close
              onClose={() => this.setState({ selected: null, project: null })}
            />
          </div>
          {/* <h6 className="mt-5 text-center ">Choose Your Project Type</h6> */}
          {this.renderOptions(type.id)}
        </div>
      );
    }
    return null;
  };

  renderButton = () => {
    if (this.state.selected && this.state.project) {
      return (
        <button
          type="button"
          className="btn btn-warning rounded-pill text-uppercase"
          onClick={this.onClickConfirm}
        >
          Confirm
        </button>
      );
    }
    if (this.state.selected) {
      return (
        <button
          type="button"
          className="btn btn-grey rounded-pill text-uppercase"
        >
          Choose Project Type
        </button>
      );
    }

    return (
      <button
        type="button"
        className="btn btn-grey rounded-pill text-uppercase"
      >
        Select an option
      </button>
    );
  };

  render() {
    if (!this.props.projectTypes) {
      return null;
    }
    const { selected } = this.state;
    return (
      <>
        <div className="d-md-block text-center heading_box">
          <h5 className="heading1">Project type</h5>
          <p className="sub_heading">Choose your project type</p>
        </div>

        <div className="overflow_bg">
          <div className="row justify-content-center">
            <div className="col-12">
              {!selected && (
                <div className="row justify-content-center">
                  {this.props.projectTypes.map(type => (
                    <Card
                      key={type.id}
                      icon={type.icon_url}
                      text={type.name}
                      onClick={() =>
                        this.setState({
                          selected: { id: type.id, name: type.name },
                        })
                      }
                    />
                  ))}
                </div>
              )}
              {selected && this.renderSelected()}
              <div className="text-center my-5 main_btn">
                {this.renderButton()}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ projectTypes, projects }) => ({
  projectTypes,
  projects,
});
export default connect(mapStateToProps, { getProjectTypes, getProjects })(
  ProjectType
);
