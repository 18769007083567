import React from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import * as Constant from '../constants';

const datetimeObj = {
  date: '',
  arrival_time: '',
  departure_time: '',
  total_hours: '',
  location: [],
};

class DateTime extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      datetimes: props.datetimes,
      isAdding: props.datetimes.length === 0,
      ...datetimeObj,
    };
  }

  componentDidMount = () => {
    // eslint-disable-next-line no-extend-native
    Date.prototype.addHours = function (h) {
      this.setHours(this.getHours() + h);
      return this;
    };
  };

  onClickConfirm = () => {
    this.props.onConfirm(this.state.datetimes);
  };

  onClickChange = () => {
    const {
      date,
      arrival_time,
      departure_time,
      total_hours,
      duration,
      location,
    } = this.state;
    const datetimes = [...this.state.datetimes];
    this.setState({
      datetimes: datetimes.map(dt =>
        dt.isEditing
          ? {
              ...dt,
              date,
              arrival_time,
              departure_time,
              total_hours,
              duration,
              location,
              isEditing: null,
            }
          : dt
      ),
      isEditing: null,
    });
  };

  onClickConfirmDatetime = () => {
    const {
      date,
      arrival_time,
      departure_time,
      total_hours,
      duration,
      location,
    } = this.state;
    const datetimes = [...this.state.datetimes];
    datetimes.push({
      date,
      arrival_time,
      departure_time,
      total_hours,
      duration,
      location,
    });
    this.setState({ datetimes, isAdding: false });
  };

  onEditTime = index => {
    const { datetimes } = this.state;
    const dt = datetimes.find((dt, i) => i === index);
    this.setState({
      ...dt,
      isEditing: true,
      isAdding: false,
      datetimes: [
        ...datetimes.map((dt, i) =>
          i === index ? { ...dt, isEditing: true } : { ...dt, isEditing: null }
        ),
      ],
    });
  };

  onRemoveTime = () => {
    const { datetimes, isAdding } = this.state;

    if (isAdding) {
      this.setState({ isAdding: false });
    } else {
      this.setState(
        {
          isEditing: null,
          datetimes: datetimes.filter(dt => !dt.isEditing),
        },
        () => {
          if (this.state.datetimes.length === 0) {
            this.setState({ isAdding: true, ...datetimeObj });
          }
        }
      );
    }
  };

  onChangeTime = (name, time) => {
    this.setState({ [name]: time }, this.setTotalHours);
  };

  setTotalHours() {
    const { datetimes, date, arrival_time, departure_time } = this.state;
    const { booking_type } = this.props;
    if (arrival_time && departure_time) {
      let total_time = Math.round(
        (new Date(departure_time) - new Date(arrival_time)) / 1000
      );
      if (total_time > 10) {
        total_time = parseInt(total_time / 60) * 60;
        const hours = parseInt(total_time / 3600);
        const minutes =
          Math.round(parseInt((total_time % 3600) / 60) / 15) * 15;
        const arr = [];
        let th_error = true;
        if (hours) {
          const minimum_hours = booking_type === Constant.PACKAGE ? 4 : 2;
          if (hours >= minimum_hours) {
            th_error = false;
          }
          arr.push(hours + ' hours');
        }
        if (minutes) {
          arr.push(minutes + ' mins');
        }
        this.setState({
          total_hours: arr.join(' '),
          duration: Math.ceil(total_time / 3600),
          th_error,
          t_error: false,
          td_error: false,
        });

        // check for overlap time
        for (let dt in datetimes) {
          if (
            !datetimes[dt].isEditing &&
            new Date(datetimes[dt].date).getTime() === new Date(date).getTime()
          ) {
            if (
              !(
                new Date(datetimes[dt].departure_time) <= arrival_time ||
                departure_time <= new Date(datetimes[dt].arrival_time)
              )
            ) {
              this.setState({ t_error: true, td_error: true });
              return;
            }
          }
        }
        return;
      }
      this.setState({
        total_hours: '0 hours',
        duration: 0,
        th_error: false,
        t_error: true,
        td_error: false,
      });
    }
  }

  addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  renderButton = () => {
    const {
      isAdding,
      isEditing,
      date,
      arrival_time,
      departure_time,
      total_hours,
      th_error,
      t_error,
    } = this.state;

    if (!isAdding && !isEditing) {
      return (
        <button
          type="button"
          className="btn btn-warning rounded-pill text-uppercase"
          onClick={this.onClickConfirm}
        >
          Confirm
        </button>
      );
    }

    if (!date) {
      return (
        <button
          type="button"
          className="btn btn-grey rounded-pill text-uppercase"
        >
          Choose date & time
        </button>
      );
    }
    if (!arrival_time || !departure_time || !total_hours) {
      return (
        <button
          type="button"
          className="btn btn-grey rounded-pill text-uppercase"
        >
          Choose time
        </button>
      );
    }
    if (total_hours && (th_error || t_error)) {
      return (
        <button
          type="button"
          className="btn btn-grey rounded-pill text-uppercase"
        >
          Fix Issues
        </button>
      );
    }

    if (isEditing) {
      return (
        <button
          type="button"
          className="btn btn-warning rounded-pill text-uppercase"
          onClick={this.onClickChange}
        >
          Confirm Changes
        </button>
      );
    }

    return (
      <button
        type="button"
        className="btn btn-warning rounded-pill text-uppercase"
        onClick={this.onClickConfirmDatetime}
      >
        Confirm date & time
      </button>
    );
  };

  renderInfo = () => {
    const { datetimes, isAdding, isEditing, total_hours, th_error } =
      this.state;

    if (!isAdding && !isEditing) {
      return (
        <>
          <p className="mt-4 text-center w-75 mx-auto">
            Add {this.getNumberString(datetimes.length + 1).toLowerCase()} date
            if required
          </p>
          <div className="text-center">
            <button
              key="add"
              type="button"
              className="btn btn-warning rounded-pill plus_55"
              onClick={() => this.setState({ isAdding: true, ...datetimeObj })}
            >
              <i className="fas fa-plus"></i>
            </button>
          </div>
        </>
      );
    }

    if (datetimes.length > 0) {
      return (
        <>
          <p className="mt-4 text-center w-75 mx-auto">Cancel Date</p>
          <div className="text-center">
            <button
              key="remove"
              type="button"
              className="btn btn-danger rounded-pill plus_55"
              onClick={this.onRemoveTime}
            >
              <i className="fas fa-times"></i>
            </button>
          </div>
        </>
      );
    }

    if (total_hours && !th_error && !isEditing) {
      return (
        <p className="mt-4 text-center w-75 mx-auto">
          If you would like to add another date you can do so in the next step
        </p>
      );
    }
    return null;
  };

  getNumberString = num => {
    switch (num) {
      case 1:
        return 'First';
      case 2:
        return 'Second';
      case 3:
        return 'Third';
      case 4:
        return 'Fourth';
      case 5:
        return 'Fifth';
      default:
        return 'Other';
    }
  };

  renderDateTimes = () => {
    const { datetimes } = this.state;
    if (datetimes.length > 0) {
      return datetimes.map((dt, i) => (
        <div className="mb_51" key={i}>
          <p className="p_16">{this.getNumberString(i + 1)} Date</p>
          {dt.isEditing && this.renderDateTimeForm()}
          {!dt.isEditing && (
            <div className="form-group mt_26">
              <div className="custom-control custom-checkbox b_r_15 mr-sm-2 payment_check2 bg-warning text-dark">
                <div>
                  <p className="m-0 text-dark font-weight-bold">
                    {moment(dt.date).format('dddd Do MMMM yyyy')}
                  </p>
                  <p className="m-0 text-dark font-weight-bold">
                    At {moment(dt.arrival_time).format('h:mma')} for{' '}
                    {dt.total_hours}
                  </p>
                </div>
                <div className="search_icon" onClick={() => this.onEditTime(i)}>
                  <img src="/img/pencil_b.svg" alt="" />
                </div>
              </div>
            </div>
          )}
        </div>
      ));
    }
    return null;
  };

  renderDateTimeForm = () => {
    const {
      date,
      arrival_time,
      departure_time,
      total_hours,
      td_error,
      th_error,
      t_error,
    } = this.state;
    const { booking_type } = this.props;

    return (
      <form className="custom-form">
        <div className="form-row mb-0">
          <div className="form-group mb-0 col-md-12">
            <label htmlFor="date">Date</label>
            <DatePicker
              className="form-control h_55"
              placeholderText="Choose date..."
              dateFormat="eeee do MMMM yyyy"
              minDate={this.addDays(new Date(), 2)}
              selected={date ? new Date(date) : date}
              onChange={date => this.setState({ date })}
              onFocus={e => e.target.blur()}
              showMonthDropdown
              showYearDropdown
            />
          </div>
        </div>
        <div
          className={`form-group mb-0 c-time ${t_error ? 'text-danger' : ''}`}
          disabled={!date}
        >
          <label htmlFor="arrival_time">Arrival Time</label>
          <DatePicker
            className={`form-control h_55 ${
              t_error ? 'border-danger text-danger' : ''
            }`}
            placeholderText="Choose time..."
            dateFormat="h:mma"
            timeFormat="h:mma"
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={booking_type === Constant.PACKAGE ? 60 : 15}
            timeCaption="Time"
            disabled={!date}
            openToDate={
              arrival_time
                ? new Date(arrival_time)
                : new Date(date).setHours(9, 0, 0, 0)
            }
            selected={arrival_time ? new Date(arrival_time) : arrival_time}
            onChange={arrival_time =>
              this.onChangeTime('arrival_time', arrival_time)
            }
            onFocus={e => e.target.blur()}
          />
        </div>

        <div className="form-row mb-0">
          <div
            className={`form-group col-6 c-time ${
              t_error ? 'text-danger' : ''
            }`}
            disabled={!arrival_time}
          >
            <label htmlFor="departure_time">Departure Time</label>
            <DatePicker
              className={`form-control h_55 ${
                t_error ? 'border-danger text-danger' : ''
              }`}
              placeholderText="Choose time..."
              dateFormat="h:mma"
              timeFormat="h:mma"
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={booking_type === Constant.PACKAGE ? 60 : 15}
              timeCaption="Time"
              disabled={!arrival_time}
              openToDate={
                departure_time
                  ? new Date(departure_time)
                  : arrival_time
                  ? new Date(arrival_time).addHours(6)
                  : new Date(date).setHours(16, 0, 0, 0)
              }
              selected={
                departure_time ? new Date(departure_time) : departure_time
              }
              onChange={departure_time =>
                this.onChangeTime('departure_time', departure_time)
              }
              onFocus={e => e.target.blur()}
            />
          </div>
          <div
            className={`form-group col-6 ${th_error ? 'text-danger' : ''}`}
            disabled={!arrival_time}
          >
            <label htmlFor="total_hours">Total Hours</label>
            <input
              type="text"
              className={`form-control h_55 ${
                th_error ? 'border-danger text-danger' : ''
              }`}
              name="total_hours"
              id="total_hours"
              placeholder="Hours..."
              value={total_hours}
              readOnly
              disabled={!arrival_time}
            />
          </div>
          {!td_error && th_error && (
            <div className="offset-6 col-6 text-danger">
              Minimum {booking_type === Constant.PACKAGE ? 4 : 2} hours
            </div>
          )}
          {td_error && (
            <div className="col-12 text-danger">
              Timings overlap. If you would like to book additional crew, please
              go back or make a separate booking
            </div>
          )}
          {!td_error && t_error && (
            <div className="col-12 text-danger">Incompatible timings</div>
          )}
        </div>
      </form>
    );
  };

  render() {
    const { datetimes, isAdding } = this.state;
    return (
      <>
        <div className="d-md-block text-center heading_box">
          <h5 className="heading1">Date & time</h5>
          <p className="sub_heading">
            Choose the date and times you need our crew. You can add more dates
            in the next section.
          </p>
        </div>

        <div className="overflow_bg pt-0">
          <div className="row justify-content-center">
            <div className="col-12">
              {this.renderDateTimes()}
              {isAdding && (
                <>
                  {datetimes.length > 0 && (
                    <h6 className="mb-0 mt-4">
                      {this.getNumberString(datetimes.length + 1)} Date
                    </h6>
                  )}
                  {this.renderDateTimeForm()}
                </>
              )}
            </div>
          </div>
          {this.renderInfo()}
          <div className="text-center my-5 main_btn">{this.renderButton()}</div>
        </div>
      </>
    );
  }
}

export default DateTime;
