import React from 'react';
import moment from 'moment';
import * as Constant from '../constants';
import * as Func from '../utilities/func';

class OrderSummary extends React.Component {
  getNumberString = num => {
    switch (num) {
      case 1:
        return 'First';
      case 2:
        return 'Second';
      case 3:
        return 'Third';
      case 4:
        return 'Fourth';
      case 5:
        return 'Fifth';
      default:
        return 'Other';
    }
  };

  getAddressString = location => {
    if (location.provide_later) {
      return 'Will provide later';
    }
    if (location.same_as_contact) {
      return 'Same as contact details';
    }
    if (location.addressline_1) {
      return [
        location.addressline_1,
        location.city,
        location.county,
        location.post_code,
      ].join(', ');
    }
  };

  getDatetimeCost = (dt, dti) => {
    const { booking_type, crews, packages } = this.props;

    let total = 0;
    if (booking_type === Constant.HOURLY) {
      crews.map(
        c =>
          (total +=
            dt.duration *
            c.quantity *
            parseInt(c.currency_price.replace('£', '')))
      );
    } else {
      packages.map(p => {
        let pricings = dti > 0 ? p.pricings.additional : p.pricings.initial;
        total += Func.calculatePrice(pricings, dt.duration, p.quantity);
        return null;
      });
    }

    return total;
  };

  renderServiceType = () => {
    const { booking_type } = this.props;
    if (booking_type) {
      const { crews, package_type } = this.props;
      let text = '';
      let total = 0;
      if (booking_type === Constant.HOURLY) {
        text = 'Hourly ' + crews.map(c => c.name).join(', ');
        total += this.getDatetimeCost({ duration: 1 });
      } else {
        text = package_type ? package_type.name : 'Package';
      }

      return (
        <div>
          <div className="d-flex ord_strip justify-content-between">
            <h6 className="m-0">SERVICE TYPE</h6>
            <div onClick={() => this.props.onRedoStep(1)}>
              <img src="/img/pencil.svg" alt="" />
            </div>
          </div>
          <div className="d-flex ord_strip_value justify-content-between">
            <p className="m-0">{text}</p>
            {total > 0 && (
              <span className="badge badge-warning ml-1 px-2 py-1 ">
                £{total}/hour
              </span>
            )}
          </div>
        </div>
      );
    }
    return null;
  };

  renderPackage = () => {
    const { datetimes, booking_type, packages } = this.props;
    if (booking_type === Constant.PACKAGE && packages && packages.length) {
      return (
        <div>
          <div className="d-flex ord_strip justify-content-between">
            <h6 className="m-0">PACKAGE</h6>
            <div onClick={() => this.props.onRedoStep(5)}>
              <img src="/img/pencil.svg" alt="" />
            </div>
          </div>
          <div className="">
            {packages.map(p => (
              <React.Fragment key={p.id}>
                <div className="d-flex ord_strip_value h_47 justify-content-between py-2">
                  <p className="m-0">{p.name}</p>
                  <span className="badge badge-warning ml-1 px-2 py-1 ">
                    £{Func.getPackagePrice(datetimes, p)}
                  </span>
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      );
    }
    return null;
  };

  renderProjectType = () => {
    const { project_type } = this.props;
    if (project_type) {
      const { project } = this.props;
      const text = project_type.name + (project ? ` (${project.name})` : '');
      return (
        <div>
          <div className="d-flex ord_strip justify-content-between">
            <h6 className="m-0">PROJECT TYPE</h6>
            <div onClick={() => this.props.onRedoStep(3)}>
              <img src="/img/pencil.svg" alt="" />
            </div>
          </div>
          <div className="d-flex ord_strip_value justify-content-between">
            <p className="m-0">{text}</p>
            {/* <span className="badge badge-warning ml-1 px-2 py-1 ">£99.00</span> */}
          </div>
        </div>
      );
    }
    return null;
  };

  renderYourDetails = () => {
    const { your_details } = this.props;
    if (your_details && your_details.firstname) {
      return (
        <div>
          <div className="d-flex ord_strip justify-content-between">
            <h6 className="m-0">YOUR DETAILS</h6>
            <div onClick={() => this.props.onRedoStep(7)}>
              <img src="/img/pencil.svg" alt="" />
            </div>
          </div>
          <div className="ord_strip_value_address mh_66 ">
            <p className="m-0 mb-3">
              {[your_details.firstname, your_details.lastname].join(' ')}
            </p>
            <p className="m-0 mb-3">
              {[
                your_details.addressline_1,
                your_details.city,
                your_details.county,
                your_details.post_code,
              ].join(', ')}
            </p>
            <p className="m-0 mb-3">{your_details.telephone}</p>
            <p className="m-0 ">{your_details.email}</p>
          </div>
        </div>
      );
    }
    return null;
  };

  renderDateTime = () => {
    const { datetimes } = this.props;

    if (datetimes.length) {
      return (
        <div>
          <div className="d-flex ord_strip justify-content-between">
            <h6 className="m-0">Attendance</h6>
            <div onClick={() => this.props.onRedoStep(4)}>
              <img src="/img/pencil.svg" alt="" />
            </div>
          </div>
          {/* for multiple dates */}
          {datetimes.length > 1 &&
            datetimes.map((dt, i) => (
              <div className="ord_strip_value pt-2 pb-0" key={i}>
                <div className="d-flex justify-content-between py-2">
                  <p className="m-0 fw_600">
                    {this.getNumberString(i + 1)} Date
                  </p>
                  <span className="badge badge-warning ml-1 px-2 py-1 ">
                    £{this.getDatetimeCost(dt, i) || 0}
                  </span>
                </div>
                <div className="d-flex justify-content-between py-2">
                  <p className="m-0">
                    {moment(dt.date).format('Do MMMM yyyy')} from{' '}
                    {moment(dt.arrival_time).format('h:mma')} to{' '}
                    {moment(dt.departure_time).format('h:mma')}
                  </p>
                </div>
              </div>
            ))}

          {/* for single date */}
          {datetimes.length <= 1 &&
            datetimes.map((dt, i) => (
              <div className="ord_strip_value pt-2 pb-0" key={i}>
                <div className="d-flex justify-content-between py-2">
                  <p className="m-0">
                    {moment(dt.date).format('Do MMMM yyyy')} from{' '}
                    {moment(dt.arrival_time).format('h:mma')} to{' '}
                    {moment(dt.departure_time).format('h:mma')}
                  </p>
                  <span className="badge badge-warning ml-1 px-2 py-1 ">
                    £{this.getDatetimeCost(dt, i) || 0}
                  </span>
                </div>
              </div>
            ))}
        </div>
      );
    }
    return null;
  };

  renderProjectAddress = () => {
    const { datetimes } = this.props;
    if (
      datetimes.length &&
      datetimes.find(dt => dt.location && dt.location.length > 0)
    ) {
      return (
        <div>
          <div className="d-flex ord_strip justify-content-between">
            <h6 className="m-0">PROJECT ADDRESS</h6>
            <div onClick={() => this.props.onRedoStep(8)}>
              <img src="/img/pencil.svg" alt="" />
            </div>
          </div>
          {datetimes.map((dt, i) => (
            <div className="ord_strip_value pt-2 pb-0" key={i}>
              {datetimes.length > 1 && (
                <div className="d-flex justify-content-between py-2">
                  <p className="m-0 fw_600">
                    {moment(dt.date).format('Do MMMM yyyy')}
                  </p>
                </div>
              )}

              {dt.location?.map((location, j) => (
                <div className="d-flex justify-content-between py-2" key={j}>
                  <p className="m-0">{this.getAddressString(location)}</p>
                </div>
              ))}
            </div>
          ))}
        </div>
      );
    }
    return null;
  };

  renderProjectBrief = () => {
    const { project_brief } = this.props;
    if (project_brief) {
      return (
        <div>
          <div className="d-flex ord_strip justify-content-between">
            <h6 className="m-0">PROJECT BRIEF</h6>
            <div onClick={() => this.props.onRedoStep(9)}>
              <img src="/img/pencil.svg" alt="" />
            </div>
          </div>
          {project_brief.brief && (
            <div className="d-flex ord_strip_value justify-content-between py-2">
              <p className="m-0">{project_brief.brief.substring(0, 70)}...</p>
            </div>
          )}
          {project_brief.provide_later && (
            <div className="d-flex ord_strip_value justify-content-between py-2">
              <p className="m-0">Will provide later</p>
            </div>
          )}
          {project_brief.brief_file && (
            <div className="d-flex ord_strip_value justify-content-between py-2">
              <p className="m-0">{project_brief.brief_file.original_name}</p>
              <div>
                <span className="badge badge-danger ml-3 px-2 py-1">
                  {project_brief.brief_file.original_name
                    .split('.')
                    .pop()
                    .toUpperCase()}
                </span>
              </div>
            </div>
          )}
        </div>
      );
    }
    return null;
  };

  renderExtra = () => {
    const { extras } = this.props;
    if (extras.length) {
      return (
        <div>
          <div className="d-flex ord_strip justify-content-between mb_10">
            <h6 className="m-0">EXTRAS</h6>
            <div onClick={() => this.props.onRedoStep(6)}>
              <img src="/img/pencil.svg" alt="" />
            </div>
          </div>
          {extras.map((extra, i) => (
            <div
              className="d-flex ord_strip_value h_47 justify-content-between py-2"
              key={i}
            >
              <p className="m-0">{extra.name}</p>
              <div>
                <span className="badge badge-warning ml-3 px-2 py-1">
                  +{extra.currency_price}
                </span>
              </div>
            </div>
          ))}
        </div>
      );
    }
    return null;
  };

  renderEditing = () => {
    const { editing } = this.props;
    if (editing && (editing.video || editing.photo)) {
      return (
        <div>
          <div className="d-flex ord_strip justify-content-between mt_10">
            <h6 className="m-0">EDITING</h6>
            <div onClick={() => this.props.onRedoStep(11)}>
              <img src="/img/pencil.svg" alt="" />
            </div>
          </div>
          {editing.video && (
            <div className="d-flex ord_strip_value justify-content-between py-2">
              <p className="m-0">
                Video ({editing.video.editing_option.duration})
              </p>
              <div>
                <span className="badge badge-warning ml-3 px-2 py-1">
                  £{editing.video.editing_option.price}
                </span>
              </div>
            </div>
          )}
          {editing.photo && (
            <div className="d-flex ord_strip_value justify-content-between py-2">
              <p className="m-0">
                Photo ({editing.photo.editing_option.no_of_photos})
              </p>
              <div>
                <span className="badge badge-warning ml-3 px-2 py-1">
                  £{editing.photo.editing_option.price}
                </span>
              </div>
            </div>
          )}
        </div>
      );
    }
    return null;
  };

  renderEditingBrief = () => {
    const { editing_brief } = this.props;
    if (editing_brief) {
      return (
        <div>
          <div className="d-flex ord_strip justify-content-between mb_10">
            <h6 className="m-0">EDITING BRIEF</h6>
            <div onClick={() => this.props.onRedoStep(12)}>
              <img src="/img/pencil.svg" alt="" />
            </div>
          </div>
          {editing_brief.brief && (
            <div className="d-flex ord_strip_value h_47 justify-content-between py-2">
              <p className="m-0">{editing_brief.brief.substring(0, 70)}...</p>
            </div>
          )}
          {editing_brief.provide_later && (
            <div className="d-flex ord_strip_value h_47 justify-content-between py-2">
              <p className="m-0">Will provide later</p>
            </div>
          )}
          {editing_brief.brief_file && (
            <div className="d-flex ord_strip_value h_47 justify-content-between py-2">
              <p className="m-0">{editing_brief.brief_file.original_name}</p>
              <div>
                <span className="badge badge-danger ml-3 px-2 py-1">
                  {editing_brief.brief_file.original_name
                    .split('.')
                    .pop()
                    .toUpperCase()}
                </span>
              </div>
            </div>
          )}
        </div>
      );
    }
    return null;
  };

  renderTurnaround = () => {
    const { turnaround } = this.props;
    if (turnaround && (turnaround.video || turnaround.photo)) {
      return (
        <div>
          <div className="d-flex ord_strip justify-content-between mt_10">
            <h6 className="m-0">TURNAROUND</h6>
            <div onClick={() => this.props.onRedoStep(13)}>
              <img src="/img/pencil.svg" alt="" />
            </div>
          </div>
          {turnaround.video && (
            <div className="d-flex ord_strip_value justify-content-between py-2">
              <p className="m-0">
                Video (
                {turnaround.video === Constant.DELIVERY_EXPRESS
                  ? 'Express Video Editing'
                  : 'Standard Delivery'}
                )
              </p>
              {turnaround.video === Constant.DELIVERY_EXPRESS && (
                <div>
                  <span className="badge badge-warning ml-3 px-2 py-1">
                    +20%
                  </span>
                </div>
              )}
            </div>
          )}
          {turnaround.photo && (
            <div className="d-flex ord_strip_value justify-content-between py-2">
              <p className="m-0">
                Photo (
                {turnaround.video === Constant.DELIVERY_EXPRESS
                  ? 'Express Photo Editing'
                  : 'Standard Delivery'}
                )
              </p>
              {turnaround.video === Constant.DELIVERY_EXPRESS && (
                <div>
                  <span className="badge badge-warning ml-3 px-2 py-1">
                    +20%
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
      );
    }
    return null;
  };

  renderTotal = () => {
    const { datetimes, extras, editing, turnaround } = this.props;
    let total = 0;

    // base price
    datetimes.map((dt, i) => (total += this.getDatetimeCost(dt, i)));

    // extra price
    extras.map(e => (total += parseInt(e.currency_price.replace('£', ''))));

    // editing price video
    if (editing && editing.video) {
      total += editing.video.editing_option.price * 1;
    }

    // editing price photo
    if (editing && editing.photo) {
      total += editing.photo.editing_option.price * 1;
    }

    // turnaround price video
    if (
      turnaround &&
      turnaround.video &&
      turnaround.video === Constant.DELIVERY_EXPRESS
    ) {
      total += 0.2 * total;
    }

    // turnaround price photo
    if (
      turnaround &&
      turnaround.photo &&
      turnaround.photo === Constant.DELIVERY_EXPRESS
    ) {
      total += 0.2 * total;
    }

    if (total > 0) {
      return (
        <div>
          <div className="d-flex total_cost justify-content-between align-items-center">
            <h6 className="m-0 ">TOTAL COST</h6>
            <span className="">£{total}</span>
          </div>
        </div>
      );
    }
    return null;
  };

  render() {
    const { showSummary, step } = this.props;
    return (
      <>
        <div
          className={`pt-3 full_sidebar ${
            showSummary ? 'full_sidebar_close' : ''
          } ${step === 14 ? 'show-fullwidth full_sidebar_close' : ''}`}
        >
          <div className="pr-md-5 pr-3 pl-3">
            {this.props.isFinal && (
              <>
                <div
                  className="d-flex w_40 my-3"
                  onClick={this.props.onClickBack}
                >
                  <img src="/img/back.svg" alt="" />
                </div>
                <h5 className="d-flex justify-content-between align-items-center heading1">
                  <span>Order Summary</span>
                </h5>
                <p className="summary_p">
                  Here is a summary of your order and costs. If you would like
                  to make any amendments, please tap on the pencil icon next to
                  the relevant section.
                </p>
              </>
            )}
            {!this.props.isFinal && (
              <>
                <h5 className="d-flex justify-content-between align-items-center heading1">
                  <span>
                    Order
                    <br />
                    Summary
                  </span>
                  <span
                    className="d-inline-block d-md-none d-sm-block"
                    onClick={this.props.onHideSummary}
                  >
                    <img src="/img/close.svg" alt="" />
                  </span>
                </h5>
                <p>Tap on a section heading to redo that section</p>
              </>
            )}
          </div>

          {this.renderServiceType()}
          {this.renderPackage()}
          {this.renderProjectType()}
          {this.renderYourDetails()}
          {this.renderDateTime()}
          {this.renderProjectAddress()}
          {this.renderProjectBrief()}
          {this.renderExtra()}
          {this.renderEditing()}
          {this.renderEditingBrief()}
          {this.renderTurnaround()}
          {this.renderTotal()}
        </div>
        {this.props.isFinal && (
          <div className="text-center my-5 main_btn left_50">
            <button
              type="button"
              className="btn btn-warning rounded-pill text-uppercase text-center "
              onClick={this.props.onConfirm}
            >
              Go to contract
            </button>
          </div>
        )}
      </>
    );
  }
}

export default OrderSummary;
