import React from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';

class YourDetails extends React.Component {
  constructor(props) {
    super(props);

    const your_details = props.your_details
      ? props.your_details
      : {
          firstname: '',
          lastname: '',
          organisation: '',
          address: '',
          addressline_1: '',
          addressline_2: '',
          city: '',
          county: '',
          post_code: '',
          telephone: '',
          email: '',
        };

    this.state = {
      ...your_details,
      errors: {},
    };
  }

  onClickConfirm = () => {
    const {
      firstname,
      lastname,
      organisation,
      addressline_1,
      addressline_2,
      city,
      county,
      post_code,
      telephone,
      email,
    } = this.state;

    this.props.onConfirm({
      firstname,
      lastname,
      organisation,
      addressline_1,
      addressline_2,
      city,
      county,
      post_code,
      telephone,
      email,
    });
  };

  onChangeInput = ({ target }) => {
    const { name, value } = target;
    const errors = { ...this.state.errors };
    if (name === 'telephone') {
      if (value.length < 10) {
        errors.telephone = true;
      } else {
        delete errors.telephone;
      }
    }
    if (name === 'email') {
      // eslint-disable-next-line no-useless-escape
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
        delete errors.email;
      } else {
        errors.email = true;
      }
    }
    this.setState({ [name]: value, errors: { ...errors } });
  };

  handleSelect = address => {
    geocodeByAddress(address)
      .then(results => {
        const { address_components } = results[0];
        let addressline_1 = '',
          addressline_2 = '',
          city = '',
          county = '',
          post_code = '';
        for (let i = 0; i < address_components.length; i++) {
          var component = address_components[i];
          if (
            component.types.includes('street_number') ||
            component.types.includes('route')
          ) {
            if (!addressline_1) {
              addressline_1 = component.long_name;
            } else {
              addressline_1 = addressline_1 + ' ' + component.long_name;
            }
          } else if (
            component.types.includes('neighborhood') ||
            component.types.includes('sublocality') ||
            component.types.includes('locality') ||
            component.types.includes('establishment') ||
            component.types.includes('park') ||
            component.types.includes('point_of_interest')
          ) {
            if (!addressline_1) {
              addressline_1 = component.long_name;
            } else if (!addressline_2) {
              addressline_2 = component.long_name;
            }
          } else if (component.types.includes('postal_town')) {
            city = component.long_name;
          } else if (component.types.includes('administrative_area_level_2')) {
            county = component.long_name;
          } else if (component.types.includes('postal_code')) {
            post_code = component.long_name;
          }
        }

        this.setState({
          addressline_1,
          addressline_2,
          city,
          county,
          post_code,
        });
      })
      .catch(error => console.error('Error', error));
  };

  renderButton = () => {
    const {
      firstname,
      lastname,
      addressline_1,
      city,
      county,
      post_code,
      telephone,
      email,
      errors,
    } = this.state;

    if (
      firstname &&
      lastname &&
      addressline_1 &&
      city &&
      county &&
      post_code &&
      telephone &&
      email &&
      !Object.keys(errors).length
    ) {
      return (
        <button
          type="button"
          className="btn btn-warning rounded-pill text-uppercase"
          onClick={this.onClickConfirm}
        >
          Confirm
        </button>
      );
    }

    if (Object.keys(errors).length) {
      return (
        <button
          type="button"
          className="btn btn-grey rounded-pill text-uppercase"
          onClick={() => this.setState({ dirty: true })}
        >
          Fix Issues
        </button>
      );
    }

    return (
      <button
        type="button"
        className="btn btn-grey rounded-pill text-uppercase"
        onClick={() => this.setState({ dirty: true })}
      >
        Provide details
      </button>
    );
  };

  renderAddressForm = () => {
    const {
      lastname,
      addressline_1,
      addressline_2,
      city,
      county,
      post_code,
      telephone,
      email,
      errors,
      dirty,
    } = this.state;

    if (!addressline_1 && !city && !county) {
      return (
        <div className="form-row mt-4" style={{ position: 'relative' }}>
          <PlacesAutocomplete
            searchOptions={{
              componentRestrictions: { country: ['uk'] },
            }}
            value={this.state.address}
            onChange={address => this.setState({ address })}
            onSelect={this.handleSelect}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div className="form-group col-md-12">
                <input
                  {...getInputProps({
                    placeholder: 'Type address...',
                    className:
                      'form-control address_search location-search-input',
                    disabled: !lastname,
                  })}
                />
                <div className="autocomplete-dropdown-container">
                  {loading && <div>Loading...</div>}
                  {suggestions.map(suggestion => {
                    const className = suggestion.active
                      ? 'suggestion-item--active'
                      : 'suggestion-item';
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          key: suggestion.placeId,
                          className,
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
                <div className="search_icon2">
                  <i className="fas fa-search"></i>
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        </div>
      );
    }

    return (
      <>
        <div
          className={`form-group ${
            dirty && !addressline_1 ? 'form-error' : ''
          }`}
        >
          <label htmlFor="addressline_1">Address Line 1</label>
          <input
            type="text"
            className="form-control"
            name="addressline_1"
            id="addressline_1"
            placeholder="Type here..."
            value={addressline_1}
            onChange={this.onChangeInput}
          />
          {dirty && !addressline_1 && (
            <div className="error-text">Address Line 1 is required</div>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="addressline_2">Address Line 2</label>
          <input
            type="text"
            className="form-control"
            name="addressline_2"
            id="addressline_2"
            placeholder="Type here..."
            value={addressline_2}
            onChange={this.onChangeInput}
          />
        </div>

        <div className="form-row">
          <div
            className={`form-group col-6 ${dirty && !city ? 'form-error' : ''}`}
          >
            <label htmlFor="city">Town/City</label>
            <input
              type="text"
              className="form-control"
              name="city"
              id="city"
              placeholder="Type here..."
              value={city}
              onChange={this.onChangeInput}
            />
            {dirty && !city && (
              <div className="error-text">Town/City is required</div>
            )}
          </div>

          <div
            className={`form-group col-6 ${
              dirty && !county ? 'form-error' : ''
            }`}
          >
            <label htmlFor="county">County</label>
            <input
              type="text"
              className="form-control"
              name="county"
              id="county"
              placeholder="Type here..."
              value={county}
              onChange={this.onChangeInput}
            />
            {dirty && !county && (
              <div className="error-text">County is required</div>
            )}
          </div>
        </div>

        <div className="form-row">
          <div
            className={`form-group col-6 ${
              dirty && !post_code ? 'form-error' : ''
            }`}
          >
            <label htmlFor="post_code">Postcode</label>
            <input
              type="text"
              className="form-control"
              name="post_code"
              id="post_code"
              placeholder="Type here..."
              value={post_code}
              onChange={this.onChangeInput}
            />
            {dirty && !post_code && (
              <div className="error-text">Postcode is required</div>
            )}
          </div>

          <div
            className={`form-group col-6 ${
              (dirty && !telephone) || (telephone && errors.telephone)
                ? 'form-error'
                : ''
            }`}
          >
            <label htmlFor="telephone">Phone Number</label>
            <input
              type="text"
              className="form-control"
              name="telephone"
              id="telephone"
              placeholder="Type here..."
              value={telephone}
              onChange={this.onChangeInput}
            />
            {dirty && !telephone && (
              <div className="error-text">Phone number is required</div>
            )}
            {telephone && errors.telephone && (
              <div className="error-text">Provide valid phone number</div>
            )}
          </div>
        </div>
        <div
          className={`form-group ${
            (dirty && !email) || (email && errors.email) ? 'form-error' : ''
          }`}
        >
          <label htmlFor="email">Email</label>
          <input
            type="email"
            className="form-control"
            name="email"
            id="email"
            placeholder="Type here..."
            value={email}
            onChange={this.onChangeInput}
          />
          {dirty && !email && (
            <div className="error-text">Email is required</div>
          )}
          {email && errors.email && (
            <div className="error-text">Provide valid email address</div>
          )}
        </div>
      </>
    );
  };

  renderForm = () => {
    const { firstname, lastname, organisation, dirty } = this.state;

    return (
      <form className="custom-form">
        <div className="form-row">
          <div
            className={`form-group col-6 ${
              dirty && !firstname ? 'form-error' : ''
            }`}
          >
            <label htmlFor="firstname">First Name</label>
            <input
              type="text"
              className="form-control"
              name="firstname"
              id="firstname"
              placeholder="Type here..."
              value={firstname}
              onChange={this.onChangeInput}
            />
            {dirty && !firstname && (
              <div className="error-text">First Name is required</div>
            )}
          </div>

          <div
            className={`form-group col-6 ${
              dirty && !lastname ? 'form-error' : ''
            }`}
            disabled={!firstname}
          >
            <label htmlFor="lastname">Last Name</label>
            <input
              type="text"
              className="form-control"
              name="lastname"
              id="lastname"
              placeholder="Type here..."
              value={lastname}
              onChange={this.onChangeInput}
              disabled={!firstname}
            />
            {dirty && !lastname && (
              <div className="error-text">Last Name is required</div>
            )}
          </div>
        </div>

        <div className="form-group" disabled={!lastname}>
          <label htmlFor="organisation">Organisation</label>
          <input
            type="text"
            className="form-control"
            name="organisation"
            id="organisation"
            placeholder="Type here..."
            value={organisation}
            onChange={this.onChangeInput}
            disabled={!lastname}
          />
        </div>

        {this.renderAddressForm()}
      </form>
    );
  };

  render() {
    return (
      <>
        <div className="d-md-block text-center heading_box">
          <h3 className="heading1">Your details</h3>
          <p className="sub_heading">Please provide us with your details.</p>
          <p className="sub_heading">
            We will gather project details afterwards
          </p>
        </div>

        <div className="overflow_bg">
          <div className="row justify-content-center">
            <div className="col-12">{this.renderForm()}</div>
          </div>

          <div className="text-center my-5 main_btn">{this.renderButton()}</div>
        </div>
      </>
    );
  }
}

export default YourDetails;
