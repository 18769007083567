import React from 'react';

class Header extends React.Component {
  state = { addedIncart: false };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.step > this.props.step) {
      this.setState({ addedIncart: true });
      setTimeout(() => this.setState({ addedIncart: false }), 3000);
    }
  }

  renderCartImage = () => {
    const { step } = this.props;
    const { addedIncart } = this.state;
    return (
      <>
        <img
          className="basket_magic"
          src="/img/explosion.svg"
          alt=""
          style={{ visibility: addedIncart ? 'visible' : 'hidden' }}
        />
        <img
          src="/img/basket-empty.svg"
          alt=""
          style={{ visibility: step === 1 ? 'visible' : 'hidden' }}
        />
        <img
          className=""
          src="/img/basket.svg"
          alt=""
          style={{ visibility: step !== 1 ? 'visible' : 'hidden' }}
        />
      </>
    );
  };

  render() {
    return (
      <nav className="navbar navbar-light d-flex justify-content-between align-items-center my_nav px-0 sticky-top">
        {this.props.step > 1 && (
          <div className="d-flex w_40" onClick={this.props.onClickBack}>
            <img src="/img/back.svg" alt="" />
          </div>
        )}
        <div
          className="mr-15"
          // style={{ flex: 1 }}
          style={{}}
        >
          <a href="/">
            <img width="140" src="/img/logo.png" alt="" />
          </a>
        </div>
        <div
          className="cart-icon justify-content-center align-items-center"
          onClick={this.props.onShowSummary}
        >
          {this.renderCartImage()}
        </div>
      </nav>
    );
  }
}

export default Header;
