import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { loadState, saveState } from '../../utilities/localStorage';
import reducers from '../reducers';

import { LOAD_STATE } from '../../constants';

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const enhancer = composeEnhancers(
  applyMiddleware(thunk)
  // other store enhancers if any
);

const persistedState = LOAD_STATE ? loadState() : undefined;

const store = createStore(reducers, persistedState, enhancer);

store.subscribe(() => {
  saveState(store.getState());
});

export default store;
