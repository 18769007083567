import React from 'react';
import { connect } from 'react-redux';
import * as Constant from '../constants';
import { loading } from '../redux/actions';
import Api from '../utilities/api';
import Card from './common/Card';

class VideoEditing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.editing,
      options: [],
    };
  }

  componentDidMount() {
    if (this.state.selected) {
      this.getOptions();
    }
  }

  async getOptions() {
    const project_type_id = this.props.project_type?.id;
    const service_type_id = Constant.VIDEOGRAPHER;
    const editing_type_id = this.state.selected;

    window.scrollTo(0, 0);
    this.props.loading(true);

    const response = await Api.get(
      `projecteditings?editing_type_id=${editing_type_id}&project_type_id=${project_type_id}&service_type_id=${service_type_id}`
    );
    this.setState({ options: response.data });

    this.props.loading(false);
  }

  onClickConfirm = () => {
    const { selected, editing_option } = this.state;
    this.props.onConfirm({ selected, editing_option });
  };

  renderDurationSelector = () => {
    const { options, editing_option } = this.state;
    return (
      <>
        <div className="text-center mt-4">
          <h6>Get an estimate for your project</h6>
        </div>

        <select
          className="custom-select mt-3"
          value={editing_option?.id || ''}
          onChange={e =>
            this.setState({
              editing_option: options.find(
                o => o.id === parseInt(e.target.value)
              ),
            })
          }
        >
          <option value="">Choose edited duration...</option>
          {options.map(o => (
            <option key={o.id} value={o.id}>
              {o.duration}
            </option>
          ))}
        </select>
      </>
    );
  };

  renderSelected = () => {
    const { selected, editing_option } = this.state;
    if (selected === Constant.EDITING_BASIC) {
      return (
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="d-flex justify-content-center">
              <Card
                selected
                icon="/img/video_editor.svg"
                text="Basic"
                badge={
                  editing_option ? 'Approx. £' + editing_option.price : null
                }
                close
                onClose={() => this.setState({ selected: null })}
              />
            </div>
            {this.renderDurationSelector()}
            <p className="mb-4 mt-5">
            This is a simple tidy up edit ideal for speeches, ceremonies, performances, presentations and conference sessions
            </p>
            <div className="d-flex">
              <ul className="list-group">
                <li className="list-group-item bg-transparent border-0 px-0">
                  <span>
                    <img className="mr-2" src="/img/w_dot.svg" alt="" />
                  </span>
                  Suitable for projects with continuous filming from a static
                  position
                </li>
                <li className="list-group-item bg-transparent border-0 px-0">
                  <span>
                    <img className="mr-2" src="/img/w_dot.svg" alt="" />
                  </span>
                  Remove unwanted content, shorten dialogue and add titles or
                  logo
                </li>
                <li className="list-group-item bg-transparent border-0 px-0">
                  <span>
                    <img className="mr-2" src="/img/w_dot.svg" alt="" />
                  </span>
                  Includes live sound
                </li>
                <li className="list-group-item bg-transparent border-0 px-0">
                  <span>
                    <img className="mr-2" src="/img/w_dot.svg" alt="" />
                  </span>
                  Book an extra videographer or static camera for different
                  angles
                </li>
              </ul>
            </div>
          </div>
        </div>
      );
    } else if (selected === Constant.EDITING_HIGHLIGHT) {
      return (
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="d-flex justify-content-center">
              <Card
                selected
                icon="/img/star.svg"
                text="Creative"
                badge={
                  editing_option ? 'Approx. £' + editing_option.price : null
                }
                close
                onClose={() => this.setState({ selected: null })}
              />
            </div>
            {this.renderDurationSelector()}
            <p className="mb-4 mt-5">
            This is a creative highlights edit suitable for all types of events
            </p>
            <div className="d-flex">
              <ul className="list-group">
                <li className="list-group-item bg-transparent border-0 px-0">
                  <span>
                    <img className="mr-2" src="/img/w_dot.svg" alt="" />
                  </span>
                  Features the best moments from your footage
                </li>
                <li className="list-group-item bg-transparent border-0 px-0">
                  <span>
                    <img className="mr-2" src="/img/w_dot.svg" alt="" />
                  </span>
                  Edited in an engaging and enjoyable way
                </li>
                <li className="list-group-item bg-transparent border-0 px-0">
                  <span>
                    <img className="mr-2" src="/img/w_dot.svg" alt="" />
                  </span>
                  Combines soundbites, live atmosphere and dubbed music
                </li>
                <li className="list-group-item bg-transparent border-0 px-0">
                  <span>
                    <img className="mr-2" src="/img/w_dot.svg" alt="" />
                  </span>
                  Includes titles and graphics
                </li>
              </ul>
            </div>
          </div>
        </div>
      );
    } else if (selected === Constant.EDITING_CUSTOM) {
      return (
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="d-flex justify-content-center">
              <Card
                selected
                icon="/img/custom.svg"
                text="Advanced"
                badge={
                  editing_option ? 'Approx. £' + editing_option.price : null
                }
                close
                onClose={() => this.setState({ selected: null })}
              />
            </div>
            {this.renderDurationSelector()}
            <p className="mb-4 mt-5">
            This is a more complex edit for promotional, documentary or educational projects
            </p>
            <div className="d-flex">
              <ul className="list-group">
                <li className="list-group-item bg-transparent border-0 px-0">
                  <span>
                    <img className="mr-2" src="/img/w_dot.svg" alt="" />
                  </span>
                  Includes conceptual planning or narrative structuring
                </li>
                <li className="list-group-item bg-transparent border-0 px-0">
                  <span>
                    <img className="mr-2" src="/img/w_dot.svg" alt="" />
                  </span>
                  For footage that includes multiple ‘takes’ and/or voice over
                </li>
                <li className="list-group-item bg-transparent border-0 px-0">
                  <span>
                    <img className="mr-2" src="/img/w_dot.svg" alt="" />
                  </span>
                  Covers use of special effects, motion graphics or stock media
                </li>
                <li className="list-group-item bg-transparent border-0 px-0">
                  <span>
                    <img className="mr-2" src="/img/w_dot.svg" alt="" />
                  </span>
                  Suitable for projects with a wide range of media content
                </li>
              </ul>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  renderButton = () => {
    if (this.state.selected && !this.state.editing_option) {
      return (
        <button
          type="button"
          className="btn btn-grey rounded-pill text-uppercase"
        >
          Choose edited duration
        </button>
      );
    }

    if (this.state.selected) {
      return (
        <button
          type="button"
          className="btn btn-warning rounded-pill text-uppercase"
          onClick={this.onClickConfirm}
        >
          Confirm
        </button>
      );
    }

    return (
      <button
        type="button"
        className="btn btn-grey rounded-pill text-uppercase"
      >
        Select an option
      </button>
    );
  };

  render() {
    const { selected } = this.state;
    return (
      <>
        <div className="d-md-block text-center heading_box">
          <h3 className="heading1">Video Editing</h3>
          {!selected && (
            <p className="sub_heading">
              Choose one of the editing options below for full details and
              estimated costs
            </p>
          )}
          {selected && (
            <p className="sub_heading">
              Please read the description below and choose the preferred
              duration of your edited project.
            </p>
          )}
        </div>

        <div className="overflow_bg pt-0">
          <div className="">
            {!selected && (
              <div className="row justify-content-center">
                <Card
                  icon="/img/video_editor.svg"
                  text="Basic"
                  onClick={() =>
                    this.setState(
                      {
                        selected: Constant.EDITING_BASIC,
                        editing_option: null,
                      },
                      this.getOptions
                    )
                  }
                />
                <Card
                  icon="/img/star.svg"
                  text="Creative"
                  onClick={() =>
                    this.setState(
                      {
                        selected: Constant.EDITING_HIGHLIGHT,
                        editing_option: null,
                      },
                      this.getOptions
                    )
                  }
                />
                <Card
                  icon="/img/custom.svg"
                  text="Advanced"
                  onClick={() =>
                    this.setState(
                      {
                        selected: Constant.EDITING_CUSTOM,
                        editing_option: null,
                      },
                      this.getOptions
                    )
                  }
                />
              </div>
            )}
            {selected && this.renderSelected()}
          </div>

          <div className="text-center my-5 main_btn">{this.renderButton()}</div>
        </div>
      </>
    );
  }
}

export default connect(() => ({}), { loading })(VideoEditing);
