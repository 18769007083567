import React from 'react';
import * as Constant from '../constants';
import VideoEditing from './VideoEditing';
import PhotoEditing from './PhotoEditing';

class Editing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editing: props.editing ? props.editing : {},
      step: 'video',
    };
  }

  onClickConfirm = () => {
    this.props.onConfirm(this.state.editing);
  };

  onConfirmVideoEditing = video => {
    this.setState({ editing: { ...this.state.editing, video }, step: 'photo' });
  };

  onConfirmPhotoEditing = photo => {
    this.setState(
      { editing: { ...this.state.editing, photo } },
      this.onClickConfirm
    );
  };

  render() {
    const { crews, delivery } = this.props;
    if (this.state.step === 'video') {
      if (
        crews.find(
          c =>
            c.id === Constant.VIDEO_EDITOR ||
            (delivery && delivery.video === Constant.DELIVERY_EDITING)
        )
      ) {
        return (
          <VideoEditing
            project_type={this.props.project_type}
            editing={this.state.editing.video}
            onConfirm={this.onConfirmVideoEditing}
          />
        );
      } else {
        this.setState({ step: 'photo' });
      }
    }

    if (this.state.step === 'photo') {
      if (
        crews.find(
          c =>
            c.id === Constant.PHOTO_EDITOR ||
            (delivery && delivery.photo === Constant.DELIVERY_EDITING)
        )
      ) {
        return (
          <PhotoEditing
            project_type={this.props.project_type}
            editing={this.state.editing.photo}
            onConfirm={this.onConfirmPhotoEditing}
          />
        );
      } else {
        this.onClickConfirm();
        return null;
      }
    }
    return null;
  }
}

export default Editing;
