import React from 'react';
import * as Constant from '../constants';

class BookingType extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      payment_amount: Constant.PAYMENT_FULL,
      payment_method: '',
      voucher_code: '',
      errors: {},
    };
  }

  onChangeInput = ({ target }) => {
    const { name, value } = target;
    const errors = { ...this.state.errors };
    this.setState({ [name]: value, errors: { ...errors } });
  };

  onClickConfirm = () => {
    const { payment_amount, payment_method, voucher_code } = this.state;
    this.props.onConfirm(payment_amount, payment_method, voucher_code);
  };

  renderButton = () => {
    const { payment_amount, payment_method, voucher_code, errors } = this.state;
    if (!payment_amount) {
      return (
        <button
          type="button"
          className="btn btn-grey rounded-pill text-uppercase"
        >
          Select payment amount
        </button>
      );
    }

    if (!payment_method) {
      return (
        <button
          type="button"
          className="btn btn-grey rounded-pill text-uppercase"
        >
          Select payment method
        </button>
      );
    }

    if (voucher_code && errors.voucher_code) {
      return (
        <button
          type="button"
          className="btn btn-grey rounded-pill text-uppercase"
        >
          Fix Errors
        </button>
      );
    }

    return (
      <button
        type="button"
        className="btn btn-warning rounded-pill text-uppercase"
        onClick={this.onClickConfirm}
      >
        Make Payment
      </button>
    );
  };

  render() {
    const { payment_amount, payment_method, voucher_code, errors } = this.state;
    return (
      <div className="content-left w-100">
        <div className="d-md-block text-center heading_box top_0 pt-4">
          <div className="d-flex w_40" onClick={this.props.onClickBack}>
            <img src="/img/back.svg" alt="" />
          </div>
          <h5 className="heading1">Payment Method</h5>
          <p className="sub_heading mt-3">Choose your preferred payment method</p>
        </div>

        <div className="overflow_bg max-600">
          <div className="row justify-content-center">
            <div className="col-12">
              <h6 className="mb-4 mt-0">Payment Amount</h6>
              <div>
                <div className={``}>
                  <div
                    className={`my_radio_bg m-0 h_50 top_left_top_right  ${
                      payment_amount === Constant.PAYMENT_FULL ? 'checked' : ''
                    }`}
                    onClick={() =>
                      this.onChangeInput({
                        target: {
                          name: 'payment_amount',
                          value: Constant.PAYMENT_FULL,
                        },
                      })
                    }
                  >
                    <div className="my_checkradio rounded-circle ">
                      <div className="my_radio_img rounded-circle">
                        <div className="bullet"></div>
                      </div>
                    </div>
                    <h6 className="m-0">Pay in full</h6>
                  </div>
                </div>

                <div className={``}>
                  <div
                    className={`my_radio_bg m-0 h_50 bottom_left_bottom_right ${
                      payment_amount === Constant.PAYMENT_HALF ? 'checked' : ''
                    }`}
                    onClick={() =>
                      this.onChangeInput({
                        target: {
                          name: 'payment_amount',
                          value: Constant.PAYMENT_HALF,
                        },
                      })
                    }
                  >
                    <div className="my_checkradio rounded-circle ">
                      <div className="my_radio_img rounded-circle">
                        <div className="bullet"></div>
                      </div>
                    </div>
                    <h6 className="m-0">Pay 50% deposit</h6>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 mt-4">
              <h6 className="mb-4">Payment Method</h6>
              <div>
                <div
                  className={`custom-control custom-checkbox payment_check border_top`}
                >
                  <div
                    className={`my_radio_bg m-0 h_50 top_left_top_right ${
                      payment_method === Constant.PAYMENT_METHOD1
                        ? 'checked'
                        : ''
                    }`}
                    onClick={() =>
                      this.onChangeInput({
                        target: {
                          name: 'payment_method',
                          value: Constant.PAYMENT_METHOD1,
                        },
                      })
                    }
                  >
                    <div className="my_checkradio rounded-circle">
                      <div className="my_radio_img rounded-circle">
                        <div className="bullet"></div>
                      </div>
                    </div>
                    <h6 className="m-0">Debit or Credit Card</h6>
                  </div>
                </div>

                <div className={`custom-control custom-checkbox payment_check`}>
                  <div
                    className={`my_radio_bg m-0 h_50 rounded-0 ${
                      payment_method === Constant.PAYMENT_METHOD2
                        ? 'checked'
                        : ''
                    }`}
                    onClick={() =>
                      this.onChangeInput({
                        target: {
                          name: 'payment_method',
                          value: Constant.PAYMENT_METHOD2,
                        },
                      })
                    }
                  >
                    <div className="my_checkradio rounded-circle">
                      <div className="my_radio_img rounded-circle">
                        <div className="bullet"></div>
                      </div>
                    </div>
                    <h6 className="m-0">PayPal</h6>
                  </div>
                </div>

                <div className={`custom-control custom-checkbox payment_check`}>
                  <div
                    className={`my_radio_bg m-0 h_50 rounded-0 ${
                      payment_method === Constant.PAYMENT_METHOD3
                        ? 'checked'
                        : ''
                    }`}
                    onClick={() =>
                      this.onChangeInput({
                        target: {
                          name: 'payment_method',
                          value: Constant.PAYMENT_METHOD3,
                        },
                      })
                    }
                  >
                    <div className="my_checkradio rounded-circle">
                      <div className="my_radio_img rounded-circle">
                        <div className="bullet"></div>
                      </div>
                    </div>
                    <h6 className="m-0">Apple Pay</h6>
                  </div>
                </div>

                <div
                  className={`custom-control custom-checkbox payment_check border_bottom `}
                >
                  <div
                    className={`my_radio_bg m-0 h_50 bottom_left_bottom_right ${
                      payment_method === Constant.PAYMENT_METHOD4
                        ? 'checked'
                        : ''
                    }`}
                    onClick={() =>
                      this.onChangeInput({
                        target: {
                          name: 'payment_method',
                          value: Constant.PAYMENT_METHOD4,
                        },
                      })
                    }
                  >
                    <div className="my_checkradio rounded-circle">
                      <div className="my_radio_img rounded-circle">
                        <div className="bullet"></div>
                      </div>
                    </div>
                    <h6 className="m-0">Send Invoice</h6>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 mt-4 custom-form">
              <h6 className="mb-0">Apply Voucher</h6>
              <div
                className={`form-group ${
                  errors.voucher_code ? 'text-danger' : ''
                }`}
              >
                <label htmlFor="voucher_code">Your Code</label>
                <input
                  type="text"
                  className={`form-control ${
                    errors.voucher_code ? 'border-danger text-danger' : ''
                  }`}
                  name="voucher_code"
                  id="voucher_code"
                  placeholder="Enter here..."
                  value={voucher_code}
                  onChange={this.onChangeInput}
                />
              </div>
              {errors.voucher_code && (
                <div className="text-danger">Voucher not valid</div>
              )}
            </div>
            <div className="text-center my-5 main_btn w-100">
              {this.renderButton()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BookingType;
