import React from 'react';
import { connect } from 'react-redux';

import * as Constant from '../constants';
import { getServices } from '../redux/actions';
import Card from './common/Card';

class Crew extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected:
        props.crews.length === 1 && !props.crews[0].can_be_grouped
          ? props.crews[0].id
          : null,
      crews: props.crews,
    };
  }

  componentDidMount() {
    if (!this.props.services) {
      this.props.getServices(this.props.booking_type);
    }
  }

  onClickAdd = selected => {
    if (this.state.crews.find(c => c.id === selected)) {
      this.setState({ selected });
    } else {
      const crew = this.props.services.find(c => c.id === selected);
      this.setState(prevState => ({
        selected,
        crews: [
          ...prevState.crews,
          {
            id: crew.id,
            quantity: 1,
            name: crew.name,
            currency_price: crew.currency_price,
          },
        ],
      }));
    }
    window.scrollTo(0, 0);
  };

  onClickRemove = selected => {
    this.setState(prevState => ({
      selected: null,
      crews: prevState.crews.filter(c => c.id !== selected),
    }));
  };

  onClickConfirm = () => {
    this.props.onConfirm(this.state.crews);
  };

  onClickMinus = () => {
    const { selected } = this.state;
    const index = this.state.crews.findIndex(c => c.id === selected);
    this.setState(prevState => {
      const crews = [...prevState.crews];
      if (crews[index].quantity > 1) {
        crews[index] = { ...crews[index], quantity: crews[index].quantity - 1 };
      }
      return { crews };
    });
  };

  onClickPlus = () => {
    const { selected } = this.state;
    const index = this.state.crews.findIndex(c => c.id === selected);
    this.setState(prevState => {
      const crews = [...prevState.crews];
      const limit = crews[index].id === Constant.DRONE_FILMING ? 2 : 4;
      if (crews[index].quantity < limit) {
        crews[index] = { ...crews[index], quantity: crews[index].quantity + 1 };
      }
      return { crews };
    });
  };

  renderQuantitySelector = (crew, selectedCrew) => {
    return (
      <>
        <div className="text-center mt-4">
          <h6>No. of {crew.name.replace('Filming', 'Operator')}s</h6>
        </div>

        <div className="d-flex justify-content-center quantity_box mt-4">
          <span className="bg-transparent" onClick={this.onClickMinus}>
            <img src="/img/minus.svg" alt="" />
          </span>
          <span className="mx-3">{selectedCrew.quantity}</span>
          <span className="bg-transparent" onClick={this.onClickPlus}>
            <img src="/img/plus.svg" alt="" />
          </span>
        </div>
      </>
    );
  };

  renderSelected = () => {
    const { selected } = this.state;
    const crew = this.props.services.find(c => c.id === selected);
    const selectedCrew = this.state.crews.find(c => c.id === selected);
    if (crew) {
      return (
        <div>
          <div className="row justify-content-center">
            <Card
              selected
              icon={crew.icon_url}
              text={crew.name}
              badge={crew.currency_price}
              quantity={crew.can_be_grouped ? selectedCrew.quantity : false}
              close
              onClose={() => this.onClickRemove(selected)}
            />
          </div>
          {!!crew.can_be_grouped &&
            this.renderQuantitySelector(crew, selectedCrew)}
          <p className="mt-4 text-center p_grey w-75 mx-auto">
            {crew.description}
          </p>
        </div>
      );
    }
    return null;
  };

  renderButton = () => {
    const { selected, crews } = this.state;
    const selectedCrew = this.props.services.find(c => c.id === selected);
    if (selected && selectedCrew.can_be_grouped) {
      return (
        <button
          type="button"
          className="btn btn-warning rounded-pill text-uppercase"
          onClick={() => this.setState({ selected: null })}
        >
          Add to your Project
        </button>
      );
    }

    if (crews.length > 0) {
      return (
        <button
          type="button"
          className="btn btn-warning rounded-pill text-uppercase"
          onClick={this.onClickConfirm}
        >
          Confirm your crew
        </button>
      );
    }

    return (
      <button
        type="button"
        className="btn btn-grey rounded-pill text-uppercase"
      >
        Select an option
      </button>
    );
  };

  render() {
    if (!this.props.services) {
      return null;
    }
    const { selected, crews } = this.state;
    return (
      <>
        <div className="d-md-block text-center heading_box">
          <h5 className="heading1">Your Crew</h5>
          <p className="sub_heading">
            Choose the experts you need for your project. You can select more
            than one type
          </p>
        </div>

        <div className="overflow_bg px-md-3 px-1 mx-auto max_1180">
          <div className="row justify-content-center">
            <div className="col-12">
              {!selected && (
                <div className="row justify-content-center">
                  {this.props.services
                    .filter(c => (crews.length > 0 ? c.can_be_grouped : true))
                    .map(crew => {
                      const selectedCrew = crews.find(c => c.id === crew.id);
                      return (
                        <Card
                          key={crew.id}
                          selected={!!selectedCrew}
                          icon={crew.icon_url}
                          text={crew.name}
                          badge={crew.currency_price}
                          quantity={
                            crew.can_be_grouped
                              ? crews.find(c => c.id === crew.id)?.quantity
                              : false
                          }
                          close={!!selectedCrew}
                          onClose={() => this.onClickRemove(crew.id)}
                          onClick={() => this.onClickAdd(crew.id)}
                        />
                      );
                    })}
                  <br />
                  {crews.length > 0 && (
                    <p className="mt-4 text-center p_grey w-75 mx-auto">
                      You can choose editing options in the next steps if
                      required
                    </p>
                  )}
                </div>
              )}
              {selected && this.renderSelected()}
              <div className="text-center my-5 main_btn">
                {this.renderButton()}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ services }, props) => ({
  services: services[props.booking_type],
});
export default connect(mapStateToProps, { getServices })(Crew);
