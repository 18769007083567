export const LOAD_STATE = 0;

export const HOURLY = 'HOUR';
export const PACKAGE = 'PACKAGE';

export const VIDEOGRAPHER = 1;
export const PHOTOGRAPHER = 2;
export const DRONE_FILMING = 3;
export const VIDEO_EDITOR = 4;
export const PHOTO_EDITOR = 5;

export const VIDEOGRAPHY_PACKAGE = 6;
export const PHOTOGRAPHY_PACKAGE = 7;
export const COMBINED_PACKAGE = 8;

export const CORPORATE = 1;
export const PRIVATE = 2;
export const WEDDING = 3;

export const EDITING_BASIC = 1;
export const EDITING_HIGHLIGHT = 2;
export const EDITING_CUSTOM = 3;

export const DELIVERY_ORIGINAL = 'DELIVERY_ORIGINAL';
export const DELIVERY_EDITING = 'DELIVERY_EDITING';

export const DELIVERY_STANDARD = 'STANDARD_DELIVERY';
export const DELIVERY_EXPRESS = 'EXPRESS_DELIVERY';

export const PAYMENT_FULL = 'FULL';
export const PAYMENT_HALF = 'HALF';

export const PAYMENT_METHOD1 = 'DEBIT_CREDIT_CARD';
export const PAYMENT_METHOD2 = 'PAYPAL';
export const PAYMENT_METHOD3 = 'APPLE_PAY';
export const PAYMENT_METHOD4 = 'SEND_INVOICE';

export const STRIPE_KEY =
  'pk_test_51HWJP5AlIK4ZlxYyf4xdqTg0XFmy2OLpx0ASz9bXgOE5mvNswwVzlyEXQTHS6K1bjwOf46YXO3YliORFgUoArdIH00vlO6ktSY';
