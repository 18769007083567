import React from 'react';
import { connect } from 'react-redux';

import * as Constant from '../constants';
import { getServices } from '../redux/actions';
import Card from './common/Card';

class PackageType extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: props.package_type,
    };
  }

  componentDidMount() {
    if (!this.props.services) {
      this.props.getServices(this.props.booking_type);
    }
  }

  onClickConfirm = () => {
    this.props.onConfirm(this.state.selected);
  };

  renderSelected = () => {
    const { selected } = this.state;
    if (selected.id === Constant.VIDEOGRAPHY_PACKAGE) {
      return (
        <div>
          <div className="d-flex justify-content-center">
            <Card
              selected
              icon="/img/videographer.svg"
              text="Videography Package"
              close
              onClose={() => this.setState({ selected: null })}
            />
          </div>
          <p className="mt-4 text-center">
            Our videography packages include professional studio editing.
            Detailed instructions for your project will be taken in the next
            steps.
          </p>
        </div>
      );
    } else if (selected.id === Constant.PHOTOGRAPHY_PACKAGE) {
      return (
        <div>
          <div className="row justify-content-center">
            <Card
              selected
              icon="/img/photographer.svg"
              text="Photography Package"
              close
              onClose={() => this.setState({ selected: null })}
            />
          </div>
          <p className="mt-4 text-center">
            Our photography packages include professional studio processing.
            Detailed instructions for your project will be taken in the next
            steps
          </p>
        </div>
      );
    } else if (selected.id === Constant.COMBINED_PACKAGE) {
      return (
        <div>
          <div className="row justify-content-center">
            <Card
              selected
              icon={['/img/videographer.svg', '/img/photographer.svg']}
              text="Photo & Video Package"
              badge="Save £500"
              close
              onClose={() => this.setState({ selected: null })}
            />
          </div>
          <p className="mt-4 text-center">
            Our combined photo & video packages include professional studio
            editing. Detailed instructions for your project will be taken in the
            next steps.
          </p>
        </div>
      );
    }
    return null;
  };

  renderButton = () => {
    if (this.state.selected) {
      return (
        <button
          type="button"
          className="btn btn-warning rounded-pill text-uppercase"
          onClick={this.onClickConfirm}
        >
          Confirm Package
        </button>
      );
    }

    return (
      <button
        type="button"
        className="btn btn-grey rounded-pill text-uppercase"
      >
        Select an option
      </button>
    );
  };

  render() {
    const { selected } = this.state;
    return (
      <>
        <div className="d-md-block text-center heading_box">
          <h5 className="heading1">Package Type</h5>
          <p className="sub_heading">
            Choose the type of package you require for your project
          </p>
        </div>

        <div className="overflow_bg">
          <div className="">
            {!selected && (
              <div className="row justify-content-center">
                <Card
                  icon="/img/videographer.svg"
                  text="Videography Package"
                  onClick={() => {
                    this.setState({
                      selected: {
                        id: Constant.VIDEOGRAPHY_PACKAGE,
                        name: 'Videography Package',
                      },
                    });
                    window.scrollTo(0, 0);
                  }}
                  badge="Save £799"
                />
                <Card
                  icon="/img/photographer.svg"
                  text="Photography Package"
                  onClick={() => {
                    this.setState({
                      selected: {
                        id: Constant.PHOTOGRAPHY_PACKAGE,
                        name: 'Photography Package',
                      },
                    });
                    window.scrollTo(0, 0);
                  }}
                  badge="Save £499"
                />
                <Card
                  icon={['/img/videographer.svg', '/img/photographer.svg']}
                  text="Photo & Video Package"
                  onClick={() => {
                    this.setState({
                      selected: {
                        id: Constant.COMBINED_PACKAGE,
                        name: 'Photo & Video Package',
                      },
                    });
                    window.scrollTo(0, 0);
                  }}
                  badge="Save £500"
                />
              </div>
            )}
            {selected && this.renderSelected()}
            <div className="text-center my-5 main_btn">
              {this.renderButton()}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ services }, props) => ({
  services: services[props.booking_type],
});
export default connect(mapStateToProps, { getServices })(PackageType);
