import React from 'react';

const Invoice = props => {
  return (
    <div className="col-lg-12 col-md-12 content-left">
      <div className="d-md-block text-center heading_box max-600 mx-auto px-3">
        <h3 className="heading1 text-center mt-5 mt-3">INVOICE SENT</h3>

        <p className="mt-3 mb-4">
          An invoice with our bank details has been sent to the email provided.
          Please make the remittance within 48 hours. Note we cannot confirm
          your booking until payment is received. USE ANOTHER PAYMENT METHOD
        </p>        
        <button
            type="button"
            className="btn btn-warning rounded-pill text-uppercase"
            onClick={props.onConfirm}
          >
            I ACKNOWLEDGE THIS
          </button>
        </div>
        <div className="text-center my-3">
        <button
          type="button"
          className="btn btn-warning rounded-pill text-uppercase"
          onClick={props.onChangePayment}
        >
          USE ANOTHER PAYMENT METHOD
        </button>
      </div>
    </div>
  );
};

export default Invoice;
