import React from 'react';
import * as Constant from '../constants';
import Card from './common/Card';

class BookingType extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: props.selected,
    };
  }

  onSelectOption = selected => {
    if (this.state.selected === selected) {
      this.setState({ selected: null });
    } else {
      this.setState({ selected });
      setTimeout(() => {
        document
          .getElementsByClassName('opacity-0')[0]
          .classList.remove('opacity-1');
      }, 0);
    }
  };

  onClickConfirm = () => {
    this.props.onConfirm(this.state.selected);
  };

  renderInfo = () => {
    if (this.state.selected === Constant.HOURLY) {
      setTimeout(() => {
        document.getElementById('HOURLY').classList.add('opacity-1');
      }, 100);
      return (
        <div id="HOURLY" className={`opacity-0`}>
          <p className="gap_1 text-center">
          Our hourly rates enable you book our experts exactly when you need them. Key benefits are:
          </p>
          <div className="d-flex justify-content-center">
            <ul className="list-group">
              <li className="list-group-item bg-transparent border-0">
                <span>
                  <img className="mr-2" src="/img/flexible.svg" alt="" />
                </span>
                Flexibility and short notice
              </li>
              <li className="list-group-item bg-transparent border-0">
                <span>
                  <img className="mr-2" src="/img/hiring.svg" alt="" />
                </span>
                Hiring only what you need
              </li>
              <li className="list-group-item bg-transparent border-0">
                <span>
                  <img className="mr-2" src="/img/freelance.svg" alt="" />
                </span>
                Pay freelancer rates
              </li>
            </ul>
          </div>
        </div>
      );
    }

    if (this.state.selected === Constant.PACKAGE) {
      setTimeout(() => {
        document.getElementById('PACKAGE').classList.add('opacity-1');
      }, 100);
      return (
        <div id="PACKAGE" className={`opacity-0`}>
          <p className="gap_1 text-center">
          Our package deals include everything needed for your project at affordable prices. Key benefits are: 
          </p>
          <div className="d-flex justify-content-center">
            <ul className="list-group">
              <li className="list-group-item bg-transparent border-0">
                <span>
                  <img className="mr-2" src="/img/secure.svg" alt="" />
                </span>
                Secure advance booking
              </li>
              <li className="list-group-item bg-transparent border-0">
                <span>
                  <img className="mr-2" src="/img/inclusive.svg" alt="" />
                </span>
                Fully inclusive solutions
              </li>
              <li className="list-group-item bg-transparent border-0">
                <span>
                  <img className="mr-2" src="/img/money.svg" alt="" />
                </span>
                Saves time and money
              </li>
            </ul>
          </div>
        </div>
      );
    }

    return (
      <p className="gap_1 text-center">        
        Hire our staff by the hour or choose one of our all-inclusive package deals
      </p>
    );
  };

  renderButton = () => {
    if (this.state.selected) {
      return (
        <button
          type="button"
          className="btn btn-warning rounded-pill text-uppercase"
          onClick={this.onClickConfirm}
        >
          Confirm
        </button>
      );
    }

    return (
      <button
        type="button"
        className="btn btn-grey rounded-pill text-uppercase "
      >
        Select an option
      </button>
    );
  };

  render() {
    const { selected } = this.state;
    return (
      <>
        <div className="d-md-block text-center heading_box">
          <h5 className="heading1">Make a Booking</h5>
          <p className="sub_heading">
            Please choose an option below to see costs and availability
          </p>
        </div>

        <div className="overflow_bg">
          <div className="d-flex justify-content-center">
            <Card
              selected={selected === Constant.HOURLY}
              icon="/img/clock.svg"
              text="Hourly Rates"              
              onClick={() => this.onSelectOption(Constant.HOURLY)}
            />
            <Card
              selected={selected === Constant.PACKAGE}
              icon="/img/package.svg"
              text="Package Deals"
              onClick={() => this.onSelectOption(Constant.PACKAGE)}
            />
          </div>

          {this.renderInfo()}
        </div>
        <div className="text-center my-5 main_btn">{this.renderButton()}</div>
      </>
    );
  }
}

export default BookingType;
