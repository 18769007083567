import React from 'react';

const Final = props => {
  return (
    <div className="col-lg-12 col-md-12 content-left">
      <div className="d-md-block text-center heading_box">
        <h3 className="heading1 text-center mt-5 mt-3">YOU'RE DONE</h3>
        <p className="text-center">
          Thank you for your booking! You will shortly receive an email
          confirmation with a temporary password to login in to our website.
        </p>
        <p className="text-center">
          Here you will be able to change your password, make adjustments to
          your booking and pay any balance due.
        </p>

        <div>
          <div className="text-center my-5 main_btn">
            <button
              type="button"
              className="btn btn-warning rounded-pill text-uppercase"
              onClick={props.onConfirm}
            >
              Go To Homepage
            </button>
          </div>
        </div>
      </div>

      {/* <div>
        <div className="text-center my-5 main_btn">
          <button
            type="button"
            className="btn btn-warning rounded-pill text-uppercase"
            onClick={props.onConfirm}
          >
            Go To Homepage
          </button>
        </div>
      </div> */}
    </div>
  );
};

export default Final;
