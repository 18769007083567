import React from 'react';
import { connect } from 'react-redux';
import * as Constant from '../constants';
import { loading } from '../redux/actions';
import Api from '../utilities/api';
import Card from './common/Card';

class PhotoEditing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.editing,
      options: [],
    };
  }

  componentDidMount() {
    this.getOptions();
  }

  async getOptions() {
    const project_type_id = this.props.project_type?.id;
    const service_type_id = Constant.PHOTOGRAPHER;
    const editing_type_id = Constant.EDITING_BASIC;

    this.props.loading(true);

    const response = await Api.get(
      `projecteditings?editing_type_id=${editing_type_id}&project_type_id=${project_type_id}&service_type_id=${service_type_id}`
    );
    this.setState({ options: response.data });

    this.props.loading(false);
  }

  onClickConfirm = () => {
    this.props.onConfirm({ ...this.state });
  };

  renderDurationSelector = () => {
    const { options, editing_option } = this.state;
    return (
      <>
        {/* <div className="text-center mt-4">
          <h6>Get an estimate for your project</h6>
        </div> */}

        <select
          className="custom-select mt-3"
          value={editing_option?.id || ''}
          onChange={e =>
            this.setState({
              editing_option: options.find(
                o => o.id === parseInt(e.target.value)
              ),
            })
          }
        >
          <option value="">Number of images...</option>
          {options.map(o => (
            <option key={o.id} value={o.id}>
              {o.no_of_photos} images
            </option>
          ))}
        </select>
      </>
    );
  };

  renderSelected = () => {
    const { editing_option } = this.state;
    return (
      <div>
        <div className="row justify-content-center">
          <div className="col-md-6 col-12">
            <div className="d-flex justify-content-center">
              <Card
                selected
                icon="/img/video_editor.svg"
                text="Photo Editing"
                badge={
                  editing_option ? 'Approx. £' + editing_option.price : null
                }
              />
            </div>
          </div>
        </div>
        <div>{this.renderDurationSelector()}</div>
        <p className="mt-4">
        Digital photographic editing can enhance images for promotional or personal use. This includes colour grading, masking, cropping or retouching to remove any unwanted elements. Our service includes high resolution export files.
        </p>
      </div>
    );
  };

  renderButton = () => {
    if (this.state.editing_option) {
      return (
        <button
          type="button"
          className="btn btn-warning rounded-pill text-uppercase"
          onClick={this.onClickConfirm}
        >
          Confirm
        </button>
      );
    }

    return (
      <button
        type="button"
        className="btn btn-grey rounded-pill text-uppercase"
      >
        Choose number of images
      </button>
    );
  };

  render() {
    return (
      <>
        <div className="d-md-block text-center heading_box">
          <h3 className="heading1">Photo Editing</h3>
          <p className="sub_heading">
            Please read the description below and choose the number of edited
            images you require for an estimated cost
          </p>
        </div>

        <div className="overflow_bg">
          <div className="row justify-content-center">
            <div className="col-12">{this.renderSelected()}</div>
          </div>
          <p className="mb-4 mt-3">
          Our photo editing is ideal for special projects like weddings or promotional content
          </p>
          <div className="d-flex">
            <ul className="list-group">
              <li className="list-group-item bg-transparent border-0 px-0">
                <span>
                  <img className="mr-2" src="/img/w_dot.svg" alt="" />
                </span>
                Raw images are curated in our studio
              </li>
              <li className="list-group-item bg-transparent border-0 px-0">
                <span>
                  <img className="mr-2" src="/img/w_dot.svg" alt="" />
                </span>
                Selected images are digitally enhanced for best results
              </li>
              <li className="list-group-item bg-transparent border-0 px-0">
                <span>
                  <img className="mr-2" src="/img/w_dot.svg" alt="" />
                </span>
                We remove unwanted items, improve colour and appearances
              </li>
              <li className="list-group-item bg-transparent border-0 px-0">
                <span>
                  <img className="mr-2" src="/img/w_dot.svg" alt="" />
                </span>
                Includes high resolution export files
              </li>
            </ul>
          </div>

          <div className="text-center my-5 main_btn">{this.renderButton()}</div>
        </div>
      </>
    );
  }
}

export default connect(() => ({}), { loading })(PhotoEditing);
