import React from 'react';
import { connect } from 'react-redux';

const Loader = props => {
  const { isLoading } = props;

  if (isLoading) {
    return (
      <div className="loader">
        <img src="/img/loader.svg" alt="Loading..." />
      </div>
    );
  }
  return null;
};

Loader.defaultProps = {
  show: false,
};

const mapStateToProps = ({ isLoading }) => ({ isLoading });
export default connect(mapStateToProps, {})(Loader);
