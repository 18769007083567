import React from 'react';
import moment from 'moment';
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';

const addressObj = {
  provide_later: false,
  same_as_contact: false,
  organisation: '',
  address: '',
  addressline_1: '',
  addressline_2: '',
  city: '',
  county: '',
  zip_code: '',
  special_instructions: '',
  location_contact: '',
  phone: '',
  email: '',
};

class ProjectAddress extends React.Component {
  constructor(props) {
    super(props);

    const { location } = props.datetimes[0];
    this.state = {
      datetimes: [...props.datetimes],
      isAdding: !location.length,
      currentIndex: 0,
      addressIndex: 0,
      ...addressObj,
      errors: {},
    };
  }

  onClickConfirm = () => {
    this.props.onConfirm(this.state.datetimes);
  };

  onClickChange = () => {
    const {
      provide_later,
      same_as_contact,
      organisation,
      addressline_1,
      addressline_2,
      city,
      county,
      zip_code,
      special_instructions,
      location_contact,
      phone,
      email,
      currentIndex,
      addressIndex,
    } = this.state;

    const datetimes = [...this.state.datetimes];
    datetimes[currentIndex].location[addressIndex] = {
      provide_later,
      same_as_contact,
      organisation,
      addressline_1,
      addressline_2,
      city,
      county,
      zip_code,
      special_instructions,
      location_contact,
      phone,
      email,
    };
    this.setState({ datetimes, isAdding: false, isEditing: null });
  };

  onClickConfirmLocation = () => {
    const {
      provide_later,
      same_as_contact,
      organisation,
      addressline_1,
      addressline_2,
      city,
      county,
      zip_code,
      special_instructions,
      location_contact,
      phone,
      email,
      currentIndex,
      addressIndex,
    } = this.state;

    const datetimes = [...this.state.datetimes];
    datetimes[currentIndex].location[addressIndex] = {
      provide_later,
      same_as_contact,
      organisation,
      addressline_1,
      addressline_2,
      city,
      county,
      zip_code,
      special_instructions,
      location_contact,
      phone,
      email,
    };
    this.setState({ datetimes, isAdding: false });
  };

  onEditAddress = (currentIndex, addressIndex) => {
    const { datetimes } = this.state;
    this.setState({
      isEditing: true,
      isAdding: false,
      currentIndex,
      addressIndex,
      ...datetimes[currentIndex].location[addressIndex],
    });
  };

  onRemoveAddress = () => {
    const { datetimes, isAdding, currentIndex, addressIndex } = this.state;

    if (isAdding) {
      this.setState({ isAdding: false });
    } else {
      this.setState(
        {
          isEditing: null,
          datetimes: datetimes.map((dt, i) =>
            currentIndex !== i
              ? dt
              : {
                  ...dt,
                  location: dt.location.filter((_, j) => addressIndex !== j),
                }
          ),
        },
        () => {
          if (this.state.datetimes[0].location.length === 0) {
            this.setState({
              isAdding: true,
              currentIndex: 0,
              addressIndex: 0,
              ...addressObj,
              errors: {},
            });
          }
        }
      );
    }
  };

  onChangeInput = ({ target }) => {
    const { name } = target;
    const errors = { ...this.state.errors };
    const value = target.type === 'checkbox' ? target.checked : target.value;

    if (name === 'phone') {
      if (value.length < 10) {
        errors.phone = true;
      } else {
        delete errors.phone;
      }
    }
    if (name === 'email') {
      // eslint-disable-next-line no-useless-escape
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
        delete errors.email;
      } else {
        errors.email = true;
      }
    }
    this.setState({ [name]: value, errors: { ...errors } });
  };

  handleSelect = address => {
    geocodeByAddress(address)
      .then(results => {
        const { address_components } = results[0];
        let addressline_1 = '',
          addressline_2 = '',
          city = '',
          county = '',
          zip_code = '';
        for (let i = 0; i < address_components.length; i++) {
          var component = address_components[i];
          if (
            component.types.includes('street_number') ||
            component.types.includes('route')
          ) {
            if (!addressline_1) {
              addressline_1 = component.long_name;
            } else {
              addressline_1 = addressline_1 + ' ' + component.long_name;
            }
          } else if (
            component.types.includes('neighborhood') ||
            component.types.includes('sublocality') ||
            component.types.includes('locality') ||
            component.types.includes('establishment') ||
            component.types.includes('park') ||
            component.types.includes('point_of_interest')
          ) {
            if (!addressline_1) {
              addressline_1 = component.long_name;
            } else if (!addressline_2) {
              addressline_2 = component.long_name;
            }
          } else if (component.types.includes('postal_town')) {
            city = component.long_name;
          } else if (component.types.includes('administrative_area_level_2')) {
            county = component.long_name;
          } else if (component.types.includes('postal_code')) {
            zip_code = component.long_name;
          }
        }

        this.setState({ addressline_1, addressline_2, city, county, zip_code });
      })
      .catch(error => console.error('Error', error));
  };

  renderInfo = () => {
    const {
      provide_later,
      same_as_contact,
      addressline_1,
      city,
      county,
      zip_code,
      location_contact,
      phone,
      email,
      errors,
    } = this.state;

    if (
      provide_later ||
      same_as_contact ||
      (addressline_1 &&
        city &&
        county &&
        zip_code &&
        location_contact &&
        phone &&
        email &&
        !Object.keys(errors).length)
    ) {
      return (
        <p className="mt-4 text-center">
          Travel is charged at 50p/mile from our studio in Central London
          (minimum fee is £50). Bookings requiring a local overnight stay are
          charged £75 per crew member per night.
        </p>
      );
    }
    return null;
  };

  renderCancelButton = () => {
    const { datetimes, isAdding, isEditing, currentIndex } = this.state;
    if (
      isEditing ||
      (isAdding && datetimes[currentIndex].location.length > 0)
    ) {
      return (
        <>
          <p className="mt-4 text-center w-75 mx-auto">Cancel Address</p>
          <div className="text-center">
            <button
              key="remove"
              type="button"
              className="btn btn-danger rounded-pill plus_55"
              onClick={this.onRemoveAddress}
            >
              <i className="fas fa-times"></i>
            </button>
          </div>
        </>
      );
    }
  };

  renderButton = () => {
    const {
      datetimes,
      provide_later,
      same_as_contact,
      addressline_1,
      city,
      county,
      zip_code,
      location_contact,
      phone,
      email,
      errors,
      isAdding,
      isEditing,
    } = this.state;

    if (!isAdding && !isEditing) {
      const edt = datetimes.find(dt => !dt.location.length);

      if (edt) {
        return (
          <button
            type="button"
            className="btn btn-warning rounded-pill text-uppercase"
            onClick={() =>
              this.setState({
                isAdding: true,
                currentIndex: datetimes.findIndex(dt => !dt.location.length),
                addressIndex: 0,
                ...addressObj,
              })
            }
          >
            Add {moment(edt.date).format('dddd')}'s Address
          </button>
        );
      }

      return (
        <button
          type="button"
          className="btn btn-warning rounded-pill text-uppercase"
          onClick={this.onClickConfirm}
        >
          Confirm
        </button>
      );
    }

    if (provide_later || same_as_contact) {
      if (isEditing) {
        return (
          <button
            type="button"
            className="btn btn-warning rounded-pill text-uppercase"
            onClick={this.onClickChange}
          >
            Confirm Changes
          </button>
        );
      }

      return (
        <button
          type="button"
          className="btn btn-warning rounded-pill text-uppercase"
          onClick={this.onClickConfirmLocation}
        >
          Confirm
        </button>
      );
    }

    if (
      addressline_1 &&
      city &&
      county &&
      zip_code &&
      location_contact &&
      phone &&
      email &&
      !Object.keys(errors).length
    ) {
      if (isEditing) {
        return (
          <button
            type="button"
            className="btn btn-warning rounded-pill text-uppercase"
            onClick={this.onClickChange}
          >
            Confirm Changes
          </button>
        );
      }

      return (
        <button
          type="button"
          className="btn btn-warning rounded-pill text-uppercase"
          onClick={this.onClickConfirmLocation}
        >
          Confirm Address
        </button>
      );
    }

    if (Object.keys(errors).length) {
      return (
        <button
          type="button"
          className="btn btn-grey rounded-pill text-uppercase"
          onClick={() => this.setState({ dirty: true })}
        >
          Fix Issues
        </button>
      );
    }

    return (
      <button
        type="button"
        className="btn btn-grey rounded-pill text-uppercase"
        onClick={() => this.setState({ dirty: true })}
      >
        Provide address
      </button>
    );
  };

  getNumberString = num => {
    switch (num) {
      case 1:
        return 'First';
      case 2:
        return 'Second';
      case 3:
        return 'Third';
      case 4:
        return 'Fourth';
      case 5:
        return 'Fifth';
      default:
        return 'Other';
    }
  };

  renderAddressText = location => {
    if (location.provide_later) {
      return 'Will provide later';
    }
    if (location.same_as_contact) {
      return 'Same as contact details';
    }
    return location.addressline_1;
  };

  renderDateTimes = () => {
    const {
      datetimes,
      isAdding,
      isEditing,
      currentIndex,
      addressIndex,
    } = this.state;

    if (datetimes.length > 0) {
      return datetimes.map((dt, i) => (
        <div key={i}>
          {datetimes.length > 1 &&
            (dt.location.length > 0 || i === currentIndex) && (
              <h6 className="mb-4">
                {moment(dt.date).format('dddd Do MMMM yyyy')}
              </h6>
            )}

          {dt.location?.map(
            (location, j) =>
              !(isEditing && i === currentIndex && j === addressIndex) && (
                <div key={j} className="form-group">
                  {datetimes.length <= 1 && dt.location.length > 1 && (
                    <h6 className="mb-4">
                      {this.getNumberString(j + 1)} Address
                    </h6>
                  )}

                  <div className="custom-control custom-checkbox b_r_15 mr-sm-2 payment_check_address bg-warning text-dark pl-4">
                    <div>
                      <p className="m-0 text-dark font-weight-bold">
                        {this.renderAddressText(location)}
                      </p>
                    </div>
                    <div
                      className="search_icon"
                      onClick={() => this.onEditAddress(i, j)}
                    >
                      <i className="fas fa-edit"></i>
                    </div>
                  </div>
                </div>
              )
          )}

          {!isAdding && !isEditing && dt.location.length === 1 && (
            <>
              <p className="mt-4 text-center w-75 mx-auto">
                Add {this.getNumberString(dt.location.length + 1).toLowerCase()}{' '}
                address for
                <br />
                {moment(dt.date).format('Do MMMM YYYY')}, if required
              </p>
              <div className="text-center">
                <button
                  key="add"
                  type="button"
                  className="btn btn-warning rounded-pill plus_55"
                  onClick={() =>
                    this.setState({
                      isAdding: true,
                      currentIndex: i,
                      addressIndex: dt.location.length,
                      ...addressObj,
                    })
                  }
                >
                  <i className="fas fa-plus"></i>
                </button>
              </div>
            </>
          )}

          {i === currentIndex && (isAdding || isEditing) && (
            <>
              {datetimes[currentIndex].location.length > 0 && (
                <h6 className="mb-4">
                  {this.getNumberString(addressIndex + 1)} Address
                </h6>
              )}
              {this.renderForm()}
            </>
          )}
        </div>
      ));
    }
    return null;
  };

  renderAddressForm = () => {
    const {
      organisation,
      addressline_1,
      addressline_2,
      city,
      county,
      zip_code,
      special_instructions,
      location_contact,
      phone,
      email,
      errors,
      dirty,
    } = this.state;

    return (
      <form className="custom-form">
        <div className="form-group">
          <label htmlFor="organisation">Organisation or Venue Name</label>
          <input
            type="text"
            className="form-control"
            name="organisation"
            id="organisation"
            placeholder="Type here..."
            value={organisation}
            onChange={this.onChangeInput}
          />
        </div>

        <div
          className={`form-group ${
            dirty && !addressline_1 ? 'form-error' : ''
          }`}
        >
          <label htmlFor="addressline_1">Address Line 1</label>
          <input
            type="text"
            className="form-control"
            name="addressline_1"
            id="addressline_1"
            placeholder="Type here..."
            value={addressline_1}
            onChange={this.onChangeInput}
          />
          {dirty && !addressline_1 && (
            <div className="error-text">Address Line 1 is required</div>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="addressline_2">Address Line 2</label>
          <input
            type="text"
            className="form-control"
            name="addressline_2"
            id="addressline_2"
            placeholder="Type here..."
            value={addressline_2}
            onChange={this.onChangeInput}
          />
        </div>

        <div className="form-row">
          <div
            className={`form-group col-6 ${dirty && !city ? 'form-error' : ''}`}
          >
            <label htmlFor="city">Town/City</label>
            <input
              type="text"
              className="form-control"
              name="city"
              id="city"
              placeholder="Type here..."
              value={city}
              onChange={this.onChangeInput}
            />
            {dirty && !city && (
              <div className="error-text">City is required</div>
            )}
          </div>

          <div
            className={`form-group col-6 ${
              dirty && !county ? 'form-error' : ''
            }`}
          >
            <label htmlFor="county">County</label>
            <input
              type="text"
              className="form-control"
              name="county"
              id="county"
              placeholder="Type here..."
              value={county}
              onChange={this.onChangeInput}
            />
            {dirty && !county && (
              <div className="error-text">County is required</div>
            )}
          </div>
        </div>

        <div className={`form-group ${dirty && !zip_code ? 'form-error' : ''}`}>
          <label htmlFor="zip_code">Postcode</label>
          <input
            type="text"
            className="form-control"
            name="zip_code"
            id="zip_code"
            placeholder="Type here..."
            value={zip_code}
            onChange={this.onChangeInput}
          />
          {dirty && !zip_code && (
            <div className="error-text">Postcode is required</div>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="special_instructions">
            Special Instructions (eg entry code)
          </label>
          <input
            type="text"
            className="form-control"
            name="special_instructions"
            id="special_instructions"
            placeholder="Type here..."
            value={special_instructions}
            onChange={this.onChangeInput}
          />
        </div>

        <div className="form-row">
          <div
            className={`form-group col-6 ${
              dirty && !location_contact ? 'form-error' : ''
            }`}
          >
            <label htmlFor="location_contact">Location Contact</label>
            <input
              type="text"
              className="form-control"
              name="location_contact"
              id="location_contact"
              placeholder="Type here..."
              value={location_contact}
              onChange={this.onChangeInput}
            />
            {dirty && !location_contact && (
              <div className="error-text">Contact is required</div>
            )}
          </div>

          <div
            className={`form-group col-6 ${
              (dirty && !phone) || (phone && errors.phone) ? 'form-error' : ''
            }`}
          >
            <label htmlFor="phone">Contact Telephone</label>
            <input
              type="text"
              className="form-control"
              name="phone"
              id="phone"
              placeholder="Type here..."
              value={phone}
              onChange={this.onChangeInput}
            />
            {dirty && !phone && (
              <div className="error-text">Phone number is required</div>
            )}
            {phone && errors.phone && (
              <div className="error-text">Provide valid phone number</div>
            )}
          </div>
        </div>

        <div
          className={`form-group ${
            (dirty && !email) || (email && errors.email) ? 'form-error' : ''
          }`}
        >
          <label htmlFor="email">Location Contact Email</label>
          <input
            type="email"
            className="form-control"
            name="email"
            id="email"
            placeholder="Type here..."
            value={email}
            onChange={this.onChangeInput}
          />
          {dirty && !email && (
            <div className="error-text">Email is required</div>
          )}
          {email && errors.email && (
            <div className="error-text">Provide valid email address</div>
          )}
        </div>
      </form>
    );
  };

  renderForm = () => {
    const {
      datetimes,
      currentIndex,
      addressIndex,
      provide_later,
      same_as_contact,
      addressline_1,
      city,
      county,
    } = this.state;

    if (provide_later) {
      return (
        <form className="custom-form">
          <div className="form-group">
            <div className="">
              <div
                className={`my_checked_bg ${provide_later ? 'checked' : ''}`}
                onClick={() =>
                  this.onChangeInput({
                    target: {
                      type: 'checkbox',
                      name: 'provide_later',
                      checked: !provide_later,
                    },
                  })
                }
              >
                <div className="my_check">
                  <img
                    className="my_check_img"
                    src={provide_later ? '/img/tick_y.svg' : '/img/tick_b.svg'}
                    alt=""
                  />
                </div>
                <h6 className={`m-0`}>I will provide this later</h6>
              </div>
            </div>
          </div>
        </form>
      );
    }

    if (same_as_contact) {
      return (
        <form className="custom-form">
          <div className="form-group">
            <div className="">
              <div
                className={`my_checked_bg ${same_as_contact ? 'checked' : ''}`}
                onClick={() =>
                  this.onChangeInput({
                    target: {
                      type: 'checkbox',
                      name: 'same_as_contact',
                      checked: !same_as_contact,
                    },
                  })
                }
              >
                <div className="my_check">
                  <img
                    className="my_check_img"
                    src={
                      same_as_contact ? '/img/tick_y.svg' : '/img/tick_b.svg'
                    }
                    alt=""
                  />
                </div>
                <h6 className={`m-0`}>Same as contact details</h6>
              </div>
            </div>
          </div>
        </form>
      );
    }

    if (!addressline_1 && !city && !county) {
      return (
        <form className="custom-form">
          <div className="form-row" style={{ position: 'relative' }}>
            <PlacesAutocomplete
              searchOptions={{
                componentRestrictions: { country: ['uk'] },
              }}
              value={this.state.address}
              onChange={address => this.setState({ address })}
              onSelect={this.handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div className="form-group col-md-12">
                  <input
                    {...getInputProps({
                      placeholder: 'Type address...',
                      className:
                        'form-control rounded-pill location-search-input type_address',
                    })}
                  />
                  <div className="autocomplete-dropdown-container">
                    {loading && <div>Loading...</div>}
                    {suggestions.map(suggestion => {
                      const className = suggestion.active
                        ? 'suggestion-item--active'
                        : 'suggestion-item';
                      return (
                        <div
                          key={suggestion.placeId}
                          {...getSuggestionItemProps(suggestion, {
                            className,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                  <div className="search_icon2">
                    <i className="fas fa-search"></i>
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </div>

          {!datetimes[currentIndex].location.find(
            (location, j) => j !== addressIndex && location.same_as_contact
          ) && (
            <div className="form-group">
              <div className="mt-4">
                <div className="">
                  <div
                    className={`my_checked_bg ${
                      same_as_contact ? 'checked' : ''
                    }`}
                    onClick={() =>
                      this.onChangeInput({
                        target: {
                          type: 'checkbox',
                          name: 'same_as_contact',
                          checked: !same_as_contact,
                        },
                      })
                    }
                  >
                    <div className="my_check">
                      <img
                        className="my_check_img"
                        src={
                          same_as_contact
                            ? '/img/tick_y.svg'
                            : '/img/tick_b.svg'
                        }
                        alt=""
                      />
                    </div>
                    <h6 className={`m-0`}>Same as contact details</h6>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="form-group">
            <div className="mt-4">
              <div className="">
                <div
                  className={`my_checked_bg ${provide_later ? 'checked' : ''}`}
                  onClick={() =>
                    this.onChangeInput({
                      target: {
                        type: 'checkbox',
                        name: 'provide_later',
                        checked: !provide_later,
                      },
                    })
                  }
                >
                  <div className="my_check">
                    <img
                      className="my_check_img"
                      src={
                        provide_later ? '/img/tick_y.svg' : '/img/tick_b.svg'
                      }
                      alt=""
                    />
                  </div>
                  <h6 className={`m-0`}>I will provide this later</h6>
                </div>
              </div>
            </div>
          </div>
        </form>
      );
    }

    return this.renderAddressForm();
  };

  render() {
    return (
      <>
        <div className="d-md-block text-center heading_box">
          <h3 className="heading1">PROJECT ADDRESS</h3>
          <p className="sub_heading">
            Tell us where your project takes place. You can amend or update this
            information later.
          </p>
        </div>

        <div className="overflow_bg pt-0">
          <div className="row justify-content-center">
            <div className="col-12">{this.renderDateTimes()}</div>
          </div>

          {this.renderInfo()}
          {this.renderCancelButton()}
          <div className="text-center my-5 main_btn">{this.renderButton()}</div>
        </div>
      </>
    );
  }
}

export default ProjectAddress;
