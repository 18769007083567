import { combineReducers } from 'redux';
import * as types from '../actions/types';

const isLoading = (isLoading = false, action) => {
  if (action.type === types.LOADER) {
    return action.payload;
  }
  return isLoading;
};

const services = (services = {}, action) => {
  if (action.type === types.GET_SERVICES) {
    const { id, data } = action.payload;
    return { ...services, [id]: data };
  }
  if (action.type === types.GET_EXTRAS) {
    const { type, service_type, data } = action.payload;
    const sers = services[type].map(s =>
      s.id === service_type ? { ...s, extras: data } : s
    );
    return { ...services, [type]: [...sers] };
  }
  return services;
};

const projectTypes = (projectTypes = null, action) => {
  if (action.type === types.GET_PROJECT_TYPES) {
    return action.payload.data;
  }
  return projectTypes;
};

const projects = (projects = {}, action) => {
  if (action.type === types.GET_PROJECTS) {
    const { id, data } = action.payload;
    return { ...projects, [id]: data };
  }
  return projects;
};

const packages = (packages = {}, action) => {
  if (action.type === types.GET_PACKAGES) {
    const { project_type, package_type, data } = action.payload;
    return {
      ...packages,
      [project_type]: { ...packages[project_type], [package_type]: data },
    };
  }
  return packages;
};

export default combineReducers({
  isLoading,
  services,
  projectTypes,
  projects,
  packages,
});
