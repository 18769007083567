import React from 'react';
import moment from 'moment';
import { loadStripe } from '@stripe/stripe-js';

import { loadBooking, saveBooking, saveUser } from '../utilities/localStorage';
import Api from '../utilities/api';
import * as Constant from '../constants';
import Loader from './common/Loader';
import Header from './Header';
import ProgressBar from './ProgressBar';
import OrderSummary from './OrderSummary';
import BookingType from './BookingType';
import Crew from './Crew';
import PackageType from './PackageType';
import ProjectType from './ProjectType';
import DateTime from './DateTime';
import Package from './Package';
import PackageCombined from './PackageCombined';
import Extras from './Extras';
import YourDetails from './YourDetails';
import ProjectAddress from './ProjectAddress';
import ProjectBrief from './ProjectBrief';
import Delivery from './Delivery';
import Editing from './Editing';
import EditingBrief from './EditingBrief';
import Turnaround from './Turnaround';
import TermsConditions from './TermsConditions';
import Payment from './Payment';
import Final from './Final';
import FinalCancel from './FinalCancel';
import Invoice from './Invoice';

const stripePromise = loadStripe(Constant.STRIPE_KEY);

class App extends React.Component {
  constructor() {
    super();

    const persistedState = Constant.LOAD_STATE ? loadBooking() : '';
    this.state = persistedState
      ? { ...persistedState }
      : {
          showSummary: false,
          step: 1,
          totalStep: 16,

          booking_type: null,
          crews: [],
          package_type: null,
          project_type: null,
          project: null,
          datetimes: [],
          packages: [],
          extras: [],
          your_details: null,
          project_brief: null,
          editing_brief: null,

          extraIndex: 0,
        };
  }

  componentDidMount() {
    if (window.location.href.indexOf('success') > -1) {
      this.setState({ step: 17 });
    }
    if (window.location.href.indexOf('cancel') > -1) {
      this.setState({ step: 18 });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    saveBooking(this.state);
    if (prevState.step !== this.state.step) {
      window.scrollTo(0, 0);
    }
  }

  handleCheckout = async booking_id => {
    // Get Stripe.js instance
    const stripe = await stripePromise;

    // Call your backend to create the Checkout Session
    const response = await Api.get(`/stripe?booking_id=${booking_id}`);
    const session = await response.data;

    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    console.log(result);
    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    }
  };

  onClickBack = () => {
    this.setState(prevState => ({ step: prevState.step - 1 }));
  };

  onClickSaveBooking = async () => {
    const {
      booking_type,
      crews,
      package_type,
      project_type,
      project,
      datetimes,
      packages,
      extras,
      your_details,
      project_brief,
      editing_brief,
      payment_method,
    } = this.state;

    const reqData = {
      type: booking_type,
      booking_status: 'PENDING',
      project_status: 'IN_PROGRESS',
      crew: crews,
      services: package_type ? [{ ...package_type, quantity: 1 }] : [],
      project_type: project_type,
      project_id: project.id,
      date: datetimes.map(dt => ({
        ...dt,
        total_hours: dt.duration,
        date: moment(dt.date).format('yyyy-MM-DD'),
        arrival_time: moment(dt.arrival_time).format('HH:mm'),
        departure_time: moment(dt.departure_time).format('HH:mm'),
        location: dt.location.map(l => ({ ...l, country: 'UK' })),
      })),
      packages,
      extras: extras.map(e => ({ ...e, extra_id: e.id })),
      ...your_details,
      project_brief: project_brief?.brief_file?.name,
      project_brief_instruction: project_brief?.brief,
      editing_brief: editing_brief?.brief_file?.name,
      editing_brief_instruction: editing_brief?.brief,
      video_edit_turaround: 'STANDARD',
      video_edit_due_date: '2020-11-10',
      photo_edit_turaround: 'STANDARD',
      photo_edit_due_date: '2020-10-17',
    };

    try {
      const response = await Api.post('bookings', reqData);
      console.log(response);
      saveUser(response.data);

      if (payment_method === Constant.PAYMENT_METHOD4) {
        this.setState({ step: 19 });
      } else {
        this.handleCheckout(response.data.data.id);
      }
    } catch (err) {
      const data = err.response.data;
      console.log(data.errors);
      alert(data.message);
    }
  };

  renderStep = () => {
    const {
      step,
      booking_type,
      crews,
      package_type,
      project_type,
      project,
      datetimes,
      extras,
      extraIndex,
      packages,
      delivery,
    } = this.state;

    if (step === 1) {
      return (
        <BookingType
          selected={booking_type}
          onConfirm={utype => {
            if (utype !== booking_type) {
              this.setState({ extras: [] });
            }
            this.setState({ booking_type: utype, step: 2 });
          }}
        />
      );
    }

    if (step === 2) {
      if (booking_type === Constant.HOURLY) {
        return (
          <Crew
            crews={crews}
            booking_type={booking_type}
            onConfirm={crews => {
              const s_ids = crews.map(c => c.id);
              let uextras = extras.filter(e => s_ids.includes(e.service_id));
              this.setState({ crews, extras: uextras, step: 3 });
            }}
          />
        );
      }
      if (booking_type === Constant.PACKAGE) {
        return (
          <PackageType
            package_type={package_type}
            booking_type={booking_type}
            onConfirm={package_type => {
              const s_ids = [package_type.id];
              let uextras = extras.filter(e => s_ids.includes(e.service_id));
              let upackages = packages.filter(
                p => p.service_id === package_type.id
              );
              this.setState({
                package_type,
                extras: uextras,
                packages: upackages,
                step: 3,
              });
            }}
          />
        );
      }
    }

    if (step === 3) {
      return (
        <ProjectType
          project_type={project_type}
          project={project}
          onConfirm={(project_type, project) => {
            if (
              crews.length === 1 &&
              (crews[0].id === Constant.VIDEO_EDITOR ||
                crews[0].id === Constant.PHOTO_EDITOR)
            ) {
              this.setState({ project_type, project, step: 7 });
            } else {
              this.setState({ project_type, project, step: 4 });
            }
          }}
        />
      );
    }

    if (step === 4) {
      return (
        <DateTime
          booking_type={booking_type}
          datetimes={datetimes}
          onConfirm={datetimes =>
            this.setState({
              datetimes,
              step: booking_type === Constant.HOURLY ? 6 : 5,
            })
          }
        />
      );
    }

    if (step === 5) {
      // while coming back
      if (booking_type === Constant.HOURLY) {
        this.setState({ step: 4 });
        return;
      }

      return package_type.id === Constant.COMBINED_PACKAGE ? (
        <PackageCombined
          packages={this.state.packages}
          booking_type={booking_type}
          package_type={package_type}
          project_type={project_type}
          datetimes={datetimes}
          onConfirm={packages => this.setState({ packages, step: 6 })}
        />
      ) : (
        <Package
          packages={this.state.packages}
          booking_type={booking_type}
          package_type={package_type}
          project_type={project_type}
          datetimes={datetimes}
          onConfirm={packages => this.setState({ packages, step: 6 })}
        />
      );
    }

    if (step === 6) {
      if (
        crews.length === 1 &&
        (crews[0].id === Constant.VIDEO_EDITOR ||
          crews[0].id === Constant.PHOTO_EDITOR)
      ) {
        this.setState({ step: 3 });
        return;
      }
      if (booking_type === Constant.HOURLY) {
        if (crews[extraIndex]) {
          if (crews[extraIndex].id === Constant.DRONE_FILMING) {
            this.setState({ extraIndex: extraIndex + 1 });
            return;
          }
        } else {
          this.setState({ extras: [], extraIndex: 0, step: 7 });
          return;
        }
      }

      return (
        <Extras
          booking_type={booking_type}
          service_id={
            booking_type === Constant.HOURLY
              ? crews[extraIndex].id
              : package_type.id
          }
          extraIndex={extraIndex}
          project_type={project_type}
          extras={extras}
          onConfirm={extras => {
            if (booking_type === Constant.HOURLY) {
              if (crews[extraIndex + 1]) {
                if (crews[extraIndex + 1].id !== Constant.DRONE_FILMING) {
                  this.setState({ extras, extraIndex: extraIndex + 1 });
                  return;
                } else {
                  if (crews[extraIndex + 2]) {
                    this.setState({ extras, extraIndex: extraIndex + 2 });
                    return;
                  }
                }
              }
            }
            this.setState({ extras, extraIndex: 0, step: 7 });
          }}
        />
      );
    }

    if (step === 7) {
      return (
        <YourDetails
          your_details={this.state.your_details}
          onConfirm={your_details => {
            if (
              crews.length === 1 &&
              (crews[0].id === Constant.VIDEO_EDITOR ||
                crews[0].id === Constant.PHOTO_EDITOR)
            ) {
              this.setState({ your_details, step: 11 });
            } else {
              this.setState({ your_details, step: 8 });
            }
          }}
        />
      );
    }

    if (step === 8) {
      return (
        <ProjectAddress
          datetimes={this.state.datetimes}
          onConfirm={datetimes => this.setState({ datetimes, step: 9 })}
        />
      );
    }

    if (step === 9) {
      return (
        <ProjectBrief
          project_brief={this.state.project_brief}
          onConfirm={project_brief =>
            this.setState({
              project_brief,
              step: booking_type === Constant.PACKAGE ? 13 : 10,
            })
          }
        />
      );
    }

    if (step === 10) {
      // while coming back
      if (
        crews.length === 1 &&
        (crews[0].id === Constant.VIDEO_EDITOR ||
          crews[0].id === Constant.PHOTO_EDITOR)
      ) {
        this.setState({ step: 7 });
        return;
      }
      return (
        <Delivery
          crews={crews}
          delivery={this.state.delivery}
          onConfirm={delivery => {
            if (
              delivery.video !== Constant.DELIVERY_EDITING &&
              delivery.photo !== Constant.DELIVERY_EDITING
            ) {
              this.setState({ delivery, step: 14 });
            } else {
              this.setState({ delivery, step: 11 });
            }
          }}
        />
      );
    }

    if (step === 11) {
      return (
        <Editing
          crews={crews}
          project_type={project_type}
          delivery={this.state.delivery}
          editing={this.state.editing}
          onConfirm={editing => this.setState({ editing, step: 12 })}
        />
      );
    }

    if (step === 12) {
      // while coming back
      if (booking_type === Constant.PACKAGE) {
        this.setState({ step: 9 });
        return;
      }
      return (
        <EditingBrief
          editing_brief={this.state.editing_brief}
          delivery={this.state.delivery}
          onConfirm={editing_brief =>
            this.setState({ editing_brief, step: 13 })
          }
        />
      );
    }

    if (step === 13) {
      // while coming back
      if (
        delivery &&
        delivery.video !== Constant.DELIVERY_EDITING &&
        delivery.photo !== Constant.DELIVERY_EDITING
      ) {
        this.setState({ step: 10 });
        return;
      }
      return (
        <Turnaround
          booking_type={booking_type}
          crews={crews}
          package_type={package_type}
          delivery={this.state.delivery}
          turnaround={this.state.turnaround}
          onConfirm={turnaround => this.setState({ turnaround, step: 14 })}
        />
      );
    }
    return null;
  };

  render() {
    const { showSummary, step, totalStep } = this.state;
    return (
      <>
        <ProgressBar step={step} total={totalStep} />
        <div className="">
          <div className="row no-gutters">
            {step < 14 && (
              // <div className="col-xl-9 col-lg-8 col-md-7 content-left">
              <div className="content-left">
                <Header
                  step={step}
                  onClickBack={this.onClickBack}
                  onShowSummary={() => this.setState({ showSummary: true })}
                />
                {this.renderStep()}
              </div>
            )}
            {step <= 14 && (
              <OrderSummary
                {...this.state}
                isFinal={step >= 14}
                showSummary={showSummary}
                onHideSummary={() => this.setState({ showSummary: false })}
                onConfirm={() => this.setState({ step: 15 })}
                onRedoStep={step => this.setState({ step, showSummary: false })}
                onClickBack={this.onClickBack}
              />
            )}
            {step === 15 && (
              <TermsConditions
                onConfirm={() => this.setState({ step: 16 })}
                onClickBack={this.onClickBack}
              />
            )}
            {step === 16 && (
              <Payment
                onConfirm={(payment_amount, payment_method, voucher_code) => {
                  this.setState(
                    {
                      payment_amount,
                      payment_method,
                      voucher_code,
                    },
                    this.onClickSaveBooking
                  );
                }}
                onClickBack={this.onClickBack}
              />
            )}
            {step === 17 && <Final onConfirm={() => (window.location = '/')} />}
            {step === 18 && (
              <FinalCancel onConfirm={() => (window.location = '/')} />
            )}
            {step === 19 && (
              <Invoice
                onConfirm={() => this.setState({ step: 17 })}
                onChangePayment={() => this.setState({ step: 16 })}
              />
            )}
          </div>
        </div>
        <Loader />
      </>
    );
  }
}

export default App;
