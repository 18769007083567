import React from 'react';
import * as Constant from '../constants';
import Card from './common/Card';

class Delivery extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      delivery: props.delivery ? props.delivery : {},
      step: 'video',
    };
  }

  onClickConfirm = () => {
    this.props.onConfirm(this.state.delivery);
  };

  onConfirmVideo = video => {
    this.setState({ delivery: { ...this.state.delivery, video } });
  };

  onConfirmPhoto = photo => {
    this.setState({ delivery: { ...this.state.delivery, photo } });
  };

  renderInfo = () => {
    const isVideo = this.state.step === 'video';

    if (isVideo) {
      return (
        <p className="mt-4 text-center w-75">
          Your video files will be ready to download within 24 hours. If you would like studio editing of your footage, please select Video Editing option above. You can also book this later.
        </p>
      );
    }

    return (
      <p className="mt-4 text-center w-75">
        Your photographic files will be ready to download within 24 hours. If
        you would like digital editing of your images, please select the option.
        You can also book this later.
      </p>
    );
  };

  renderButton = () => {
    const { step, delivery } = this.state;
    if (delivery[step]) {
      return (
        <button
          type="button"
          className="btn btn-warning rounded-pill text-uppercase"
          onClick={() =>
            step === 'video'
              ? this.setState({ step: 'photo' })
              : this.onClickConfirm()
          }
        >
          Confirm
        </button>
      );
    }

    return (
      <button
        type="button"
        className="btn btn-grey rounded-pill text-uppercase"
      >
        Select an option
      </button>
    );
  };

  renderDelivery = (isVideo, delivery, onClickConfirm) => {
    return (
      <>
        <div className="d-md-block text-center heading_box">
          <h5 className="heading1">Delivery</h5>
          <p className="sub_heading">
            Choose how you would like to receive your files
          </p>
        </div>

        <div className="overflow_bg">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="row justify-content-center">
                <Card
                  icon="/img/file.svg"
                  text={isVideo ? 'Raw Files Only' : 'Camera Files Only'}
                  selected={delivery === Constant.DELIVERY_ORIGINAL}
                  onClick={() => onClickConfirm(Constant.DELIVERY_ORIGINAL)}
                />
                <Card
                  icon="/img/laptop.svg"
                  text={
                    isVideo
                      ? 'Video Editing Required'
                      : 'Photo Editing Required'
                  }
                  selected={delivery === Constant.DELIVERY_EDITING}
                  onClick={() => onClickConfirm(Constant.DELIVERY_EDITING)}
                />
              </div>
            </div>
            {this.renderInfo()}
            <div className="text-center my-5 main_btn"></div>
          </div>
          <div className="text-center my-5 main_btn">{this.renderButton()}</div>
        </div>
      </>
    );
  };

  render() {
    const { crews } = this.props;
    const { step, delivery } = this.state;

    if (step === 'video') {
      if (
        crews.find(
          c =>
            c.id === Constant.VIDEO_EDITOR ||
            c.id === Constant.VIDEOGRAPHER ||
            c.id === Constant.DRONE_FILMING
        )
      ) {
        return this.renderDelivery(true, delivery.video, this.onConfirmVideo);
      } else {
        this.setState({ step: 'photo' });
      }
    }

    if (step === 'photo') {
      if (
        crews.find(
          c => c.id === Constant.PHOTO_EDITOR || c.id === Constant.PHOTOGRAPHER
        )
      ) {
        return this.renderDelivery(false, delivery.photo, this.onConfirmPhoto);
      } else {
        this.onClickConfirm();
        return null;
      }
    }
    return null;
  }
}

export default Delivery;
