import React from 'react';
import * as Constant from '../constants';
import Card from './common/Card';

class Turnaround extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      turnaround: props.turnaround ? props.turnaround : {},
      step: 'video',
    };
  }

  onClickConfirm = () => {
    this.props.onConfirm(this.state.turnaround);
  };

  onConfirmVideo = video => {
    this.setState({ turnaround: { ...this.state.turnaround, video } });
  };

  onConfirmPhoto = photo => {
    this.setState({ turnaround: { ...this.state.turnaround, photo } });
  };

  renderSelected = () => {
    const { step, turnaround } = this.state;
    const isVideo = step === 'video';
    const selected = turnaround[step];

    if (selected === Constant.DELIVERY_STANDARD) {
      return (
        <div className="row justify-content-center">
          <Card
            selected
            icon="/img/clock.svg"
            text="Standard Delivery"
            close
            onClose={() =>
              this.setState({ turnaround: { ...turnaround, [step]: null } })
            }
          />
        </div>
      );
    } else if (selected === Constant.DELIVERY_EXPRESS) {
      return (
        <div className="row justify-content-center">
          <Card
            selected
            icon="/img/clock_express.svg"
            text={isVideo ? 'Express Video Editing' : 'Express Photo Editing'}
            badge="+20%"
            close
            onClose={() =>
              this.setState({ turnaround: { ...turnaround, [step]: null } })
            }
          />
        </div>
      );
    }
    return null;
  };

  renderButton = () => {
    const { step, turnaround } = this.state;
    if (turnaround[step]) {
      return (
        <button
          type="button"
          className="btn btn-warning rounded-pill text-uppercase"
          onClick={() =>
            step === 'video'
              ? this.setState({ step: 'photo' })
              : this.onClickConfirm()
          }
        >
          Confirm
        </button>
      );
    }

    return (
      <button
        type="button"
        className="btn btn-grey rounded-pill text-uppercase"
      >
        Select an option
      </button>
    );
  };

  renderTurnaround = (isVideo, selected, onClickConfirm) => {
    return (
      <>
        <div className="d-md-block text-center heading_box">
          <h3 className="heading1">Editing turnaround</h3>
          <p className="sub_heading">
            Choose the turnaround speed you require for your project
          </p>
        </div>

        <div className="overflow_bg pt-0">
          <div className="row justify-content-center">
            <div className="col-12">
              <p className="mt-4 text-center">
                {isVideo
                  ? `Studio edited films are delivered within 1 month. If you would like an express edit there is a 20% surcharge for delivery within 1 week.`
                  : `Studio edited images are delivered within 7 days. If you would like an express edit there is a 20% surcharge for delivery within 48 hours.`}
              </p>
              {!selected && (
                <div className="row justify-content-center">
                  <Card
                    icon="/img/clock.svg"
                    text="Standard Delivery"
                    onClick={() => onClickConfirm(Constant.DELIVERY_STANDARD)}
                  />
                  <Card
                    icon="/img/clock_express.svg"
                    text={
                      isVideo
                        ? 'Express Video Editing'
                        : 'Express Photo Editing'
                    }
                    onClick={() => onClickConfirm(Constant.DELIVERY_EXPRESS)}
                    badge="+20%"
                  />
                </div>
              )}
              {selected && this.renderSelected()}
            </div>
          </div>

          <div className="text-center my-5 main_btn">{this.renderButton()}</div>
        </div>
      </>
    );
  };

  render() {
    const { step, turnaround } = this.state;
    const { booking_type, crews, package_type, delivery } = this.props;

    if (step === 'video') {
      if (
        booking_type === Constant.PACKAGE
          ? package_type.id === Constant.VIDEOGRAPHY_PACKAGE ||
            package_type.id === Constant.COMBINED_PACKAGE
          : crews.find(
              c =>
                c.id === Constant.VIDEO_EDITOR ||
                (delivery && delivery.video === Constant.DELIVERY_EDITING)
            )
      ) {
        return this.renderTurnaround(
          true,
          turnaround.video,
          this.onConfirmVideo
        );
      } else {
        this.setState({ step: 'photo' });
      }
    }

    if (step === 'photo') {
      if (
        booking_type === Constant.PACKAGE
          ? package_type.id === Constant.PHOTOGRAPHY_PACKAGE ||
            package_type.id === Constant.COMBINED_PACKAGE
          : crews.find(
              c =>
                c.id === Constant.PHOTO_EDITOR ||
                (delivery && delivery.photo === Constant.DELIVERY_EDITING)
            )
      ) {
        return this.renderTurnaround(
          false,
          turnaround.photo,
          this.onConfirmPhoto
        );
      } else {
        this.onClickConfirm();
        return null;
      }
    }
    return null;
  }
}

export default Turnaround;
