import * as types from './types';
import Api from '../../utilities/api';

export const loading = isLoading => ({
  type: types.LOADER,
  payload: isLoading,
});

export const getServices = type => async dispatch => {
  dispatch(loading(true));
  const response = await Api.get(`service-types?type=${type}`);
  dispatch({
    type: types.GET_SERVICES,
    payload: { id: type, data: response.data.data },
  });
  dispatch(loading(false));
};

export const getProjectTypes = () => async dispatch => {
  dispatch(loading(true));
  const response = await Api.get('project-types');
  dispatch({
    type: types.GET_PROJECT_TYPES,
    payload: { data: response.data.data },
  });
  dispatch(loading(false));
};

export const getProjects = type => async dispatch => {
  dispatch(loading(true));
  const response = await Api.get(`projects?project_type_id=${type}`);
  dispatch({
    type: types.GET_PROJECTS,
    payload: { id: type, data: response.data.data },
  });
  dispatch(loading(false));
};

export const getPackages = (package_type, project_type) => async dispatch => {
  dispatch(loading(true));
  const response = await Api.get(
    `packages?service_id=${package_type}&project_type_id=${project_type}`
  );
  dispatch({
    type: types.GET_PACKAGES,
    payload: { project_type, package_type, data: response.data.data },
  });
  dispatch(loading(false));
};

export const getServiceExtras = (
  booking_type,
  service_type,
  project_type
) => async dispatch => {
  dispatch(loading(true));
  const response = await Api.get(
    `extras?service_id=${service_type}&project_type_id=${project_type}`
  );
  dispatch({
    type: types.GET_EXTRAS,
    payload: { type: booking_type, service_type, data: response.data.data },
  });
  dispatch(loading(false));
};
