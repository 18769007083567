import React from 'react';
import { connect } from 'react-redux';

import { getServiceExtras } from '../redux/actions';
import * as Constant from '../constants';
import Card from './common/Card2';

class Extras extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      extras: props.extras,
    };
  }

  componentDidMount() {
    if (!(this.props.service && this.props.service.extras)) {
      const { booking_type, service_id, project_type } = this.props;
      this.props.getServiceExtras(booking_type, service_id, project_type?.id);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.service_id !== nextProps.service_id) {
      if (!(nextProps.service && nextProps.service.extras)) {
        const { booking_type, service_id, project_type } = nextProps;
        this.props.getServiceExtras(booking_type, service_id, project_type?.id);
        this.setState({ extra: nextProps.extras });
      }
    }
  }

  onClickConfirm = () => {
    this.props.onConfirm(this.state.extras);
  };

  renderButton = () => {
    return (
      <button
        type="button"
        className="btn btn-warning rounded-pill text-uppercase"
        onClick={this.onClickConfirm}
      >
        {this.state.extras.length > 0 ? 'Confirm' : 'No Extras Required'}
      </button>
    );
  };

  getServiceName = service => {
    if (
      service.id === Constant.VIDEOGRAPHER ||
      service.id === Constant.VIDEOGRAPHY_PACKAGE
    ) {
      return 'Videography ';
    }
    if (
      service.id === Constant.PHOTOGRAPHER ||
      service.id === Constant.PHOTOGRAPHY_PACKAGE
    ) {
      return 'Photography ';
    }
    return '';
  };

  renderExtras = extraOptions => {
    const { extras } = this.state;

    return extraOptions.map(e => (
      <Card
        key={e.id}
        selected={extras.find(es => es.id === e.id)}
        icon={e.icon_url}
        text={e.name}
        badge={e.currency_price}
        info={e.description}
        onClickAdd={() =>
          this.setState({
            extras: [
              ...extras,
              {
                id: e.id,
                name: e.name,
                currency_price: e.currency_price,
                service_id: e.service_id,
              },
            ],
          })
        }
        onClickRemove={() =>
          this.setState({
            extras: extras.filter(extra => extra.id !== e.id),
          })
        }
      />
    ));
  };

  render() {
    if (!(this.props.service && this.props.service.extras)) {
      return null;
    }
    const { service } = this.props;
    return (
      <>
        <div className="d-md-block text-center heading_box mb-md-0 mb-0">
          <h3 className="heading1">{this.getServiceName(service)}Extras</h3>
          <p className="sub_heading">
            Add any extra services required to your order
          </p>
        </div>

        <div className="overflow_bg">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="row justify-content-center">
                {service.id !== Constant.COMBINED_PACKAGE &&
                  this.renderExtras(service.extras)}
                {service.id === Constant.COMBINED_PACKAGE && (
                  <>
                    <p className="font-weight-bold mt-3">Videography Extras</p>
                    {this.renderExtras(
                      service.extras.filter(
                        e => e.service_type === 'videography'
                      )
                    )}
                    <p className="font-weight-bold mt-5">Photography Extras</p>
                    {this.renderExtras(
                      service.extras.filter(
                        e => e.service_type === 'photography'
                      )
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="text-center my-5 main_btn">{this.renderButton()}</div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ services }, props) => ({
  service: services[props.booking_type].find(s => s.id === props.service_id),
});
export default connect(mapStateToProps, { getServiceExtras })(Extras);
