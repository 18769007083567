import React from 'react';

const Card2 = props => {
  const {
    selected,
    icon,
    text,
    badge,
    info,
    onClickAdd,
    onClickRemove,
  } = props;

  return (
    <div className="col-12">
      <div className="box_2 my-2 py-4 px-3 text-left">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <img src={icon} alt="" key={icon} />
            <span className="badge badge-warning ml-3 px-2 py-1 ">{badge}</span>
          </div>
          <div>
            {selected && (
              <button
                type="button"
                className="btn btn-warning3 add_plus"
                onClick={onClickRemove}
              >
                Added
              </button>
            )}
            {!selected && (
              <button
                type="button"
                className="btn text-dark add_plus"
                onClick={onClickAdd}
              >
                <span className="plus_text">+</span> Add
              </button>
            )}
          </div>
        </div>
        <div className="photography_extra">
          <h6 className="">{text}</h6>
          <p className="m-0">{info}</p>
        </div>
      </div>
    </div>
  );
};

Card2.defaultProps = {
  selected: false,
  badge: null,
};

export default Card2;
